import { NavLink } from 'react-router-dom';
import { useAuth } from '../firebase/auth';

import Logo from '../components/Logo';
import AuthControl from '../components/AuthControl';

import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import IconButton from '@mui/joy/IconButton';
import List from '@mui/joy/List';
import ListSubheader from '@mui/joy/ListSubheader';
import ListItem from '@mui/joy/ListItem';
import ListItemButton from '@mui/joy/ListItemButton';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import ListItemContent from '@mui/joy/ListItemContent';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Link from '@mui/joy/Link';
import Menu from '@mui/joy/Menu';
import MenuItem from '@mui/joy/MenuItem';
import MenuButton from '@mui/joy/MenuButton';
import Dropdown from '@mui/joy/Dropdown';

import MoreVert from '@mui/icons-material/MoreVert';
import BadgeOutlined from '@mui/icons-material/BadgeOutlined';
import Business from '@mui/icons-material/Business';
import DashboardOutlined from '@mui/icons-material/DashboardOutlined';
import GroupOutlined from '@mui/icons-material/GroupOutlined';
import LayersOutlined from '@mui/icons-material/LayersOutlined';
import SettingsOutlined from '@mui/icons-material/SettingsOutlined';
import SwapVerticalCircleOutlined from '@mui/icons-material/SwapVerticalCircleOutlined';

function SidebarItem({ to, icon, children, end=false }) {
  return(
    <NavLink to={to} end={end}>
      {({ isActive }) => (
        <ListItem>
          <ListItemButton variant="plain" selected={isActive} color="primary">
            <ListItemDecorator sx={{mr:-1.5, color: isActive ? "primary" : "neutral.500"}}>
              {icon}
            </ListItemDecorator>
            <ListItemContent>
              <Link
              component={Typography}
              level="body-sm"
              fontWeight={isActive ? 600 : 500}
              underline="none"
              hover="none"
              sx={{color: isActive ? "primary" : "neutral.700"}}
              > 
                {children}
              </Link>
            </ListItemContent>
          </ListItemButton>
        </ListItem>
      )}
    </NavLink>
  );
}

function closeSidebar() {
    if (typeof window !== 'undefined') {
        document.documentElement.style.removeProperty('--SideNavigation-slideIn');
        document.body.style.removeProperty('overflow');
    }
}

export default function Sidebar() {

    const { logout, authUser } = useAuth();
    const name = authUser.displayName;
    const role = authUser.role === 'admin' ? 'Admin' : 'Officer';    

    return (
        <Sheet
          className="Sidebar"
          sx={{
            position: { xs: 'fixed', md: 'sticky' },
            transform: {
              xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
              md: 'none',
            },
            bgcolor: 'background.level1',
            transition: 'transform 0.4s, width 0.4s',
            zIndex: 2,
            height: '100svh',
            width: 'var(--Sidebar-width)',
            top: 0,
            p: 2,
            flexShrink: 0,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            borderRight: '1px solid',
            borderColor: 'neutral.outlinedBorder',
          }}
        >
          <GlobalStyles
            styles={(theme) => ({
              ':root': {
                '--Sidebar-width': '220px',
                [theme.breakpoints.up('lg')]: {
                  '--Sidebar-width': '240px',
                },
              },
            })}
          />
          <Box
            className="Sidebar-overlay"
            sx={{
              position: 'fixed',
              zIndex: 9998,
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              opacity: 'var(--SideNavigation-slideIn)',
              backgroundColor: 'var(--joy-palette-background-backdrop)',
              transition: 'opacity 0.4s',
              transform: {
                xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
                lg: 'translateX(-100%)',
              },
            }}
            onClick={() => closeSidebar()}
          />
          <Logo/>
          <Box
            sx={{
              minHeight: 0,
              overflow: 'hidden auto',
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <List
              size="sm"
              sx={{
                '--List-nestedInsetStart': '30px',
                '--ListItem-radius': (theme) => theme.vars.radius.sm,
              }}
            >
              <ListSubheader>Overview</ListSubheader>
              <SidebarItem to="/" icon={<DashboardOutlined />} end>Dashboard</SidebarItem>
              <AuthControl.AdminOrOfficer>
              <ListSubheader>Verify</ListSubheader>
              <SidebarItem to="/customers" icon={<GroupOutlined />}>Customers - KYC</SidebarItem>
              <SidebarItem to="/businesses" icon={<Business />}>Businesses - KYB</SidebarItem>
              </AuthControl.AdminOrOfficer>
              <AuthControl.AdminOrOfficer>
              <ListSubheader>Monitor</ListSubheader>
              <SidebarItem to="/transactions" icon={<SwapVerticalCircleOutlined />}>Transactions</SidebarItem>
              </AuthControl.AdminOrOfficer>
              <ListSubheader>Settings</ListSubheader>
                <AuthControl.AdminOrOfficer>
                  <SidebarItem to="/levels" icon={<LayersOutlined />}>Levels</SidebarItem>
                </AuthControl.AdminOrOfficer>
                <AuthControl.Admin>
                  <SidebarItem to="/members" icon={<BadgeOutlined />}>Team</SidebarItem>
                </AuthControl.Admin>
                <SidebarItem to="/settings" icon={<SettingsOutlined />}>Settings</SidebarItem>
            </List>
          </Box>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <Box sx={{ minWidth: 0, flex: 1 }}>
              <Typography level="title-sm">{name}</Typography>
              <Typography level="body-xs" color="primary">{role}</Typography>
            </Box>
            <Dropdown
            >
              <MenuButton
                variant="soft"
                color="primary"
                slots={{ root: IconButton }}
              >
                  <MoreVert />
              </MenuButton>
              <Menu>
                  <MenuItem color="danger" onClick={() => logout()}>Logout</MenuItem>
              </Menu>
          </Dropdown>
          </Box>
        </Sheet>
      );
}
