import React, { useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function BarChart({ data }) {
  const chartRef = useRef(null);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          tickLength: 0,
        },
        ticks: {
          color: "#00000050", // Adjust the color if needed
          font: {
            size: 12, // Adjust the size if needed
          },
          padding: 10
        }
      },
      y: {
        min: 0,
        grid: {
          tickLength: 0,
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          callback: function (value) { if (value % 1 === 0 && value > 0) { return value; } },
          color: "#00000050",
          padding: 10,
        }
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "rgb(255,255,255)",
        bodyColor: "#858796",
        titleFont: {
          size: 14,
        },
        bodyFont: {
          size: 14,
        },
        titleMarginBottom: 10,
        titleColor: "#6e707e",
        borderColor: "#dddfeb",
        borderWidth: 1,
        padding: {
          x: 15,
          y: 15,
        },
        displayColors: false,
        intersect: false,
        mode: "index",
        caretPadding: 10,
      },
    },
  };

  const chartData = {
    ...data,
    datasets: data.datasets.map(dataset => ({
      ...dataset,
      backgroundColor: "#443DF6",
      borderColor: "#443DF6",
      hoverBackgroundColor: "#443DF6",
      hoverBorderColor: "#443DF6b",
      borderWidth: 1,
    })),
  };

  return (
    <div style={{ position: 'relative', minHeight: '200px', maxHeight: '200px' }}>
      <Bar ref={chartRef} data={chartData} options={options} />
    </div>
  );
}