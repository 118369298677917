import { useState } from "react";
import { db } from "../firebase/firebase";
import { collection, addDoc, Timestamp } from "firebase/firestore";
import { useAuth, useCompany } from "../firebase/auth";
import { useSnackbar } from "./../utils/SnackbarContext";

import Button from "@mui/joy/Button";
import Chip from "@mui/joy/Chip";
import Alert from "@mui/joy/Alert";
import Typography from "@mui/joy/Typography";
import Accordion from "@mui/joy/Accordion";
import AccordionGroup from "@mui/joy/AccordionGroup";
import AccordionSummary from "@mui/joy/AccordionSummary";
import AccordionDetails from "@mui/joy/AccordionDetails";
import Drawer from "@mui/joy/Drawer";
import DialogTitle from "@mui/joy/DialogTitle";
import Stack from "@mui/joy/Stack";
import ModalClose from "@mui/joy/ModalClose";
import QRLink from './QRLink';

import CheckCircle from "@mui/icons-material/CheckCircle";

export default function ResubmissionModal({ customer, show, selectedTags, setSelectedTags, setShow, resubmissionFields, level, onComplete }) {
  const { authUser } = useAuth();
  const { authCompany } = useCompany();
  const showSnackbar = useSnackbar();

  const [link, setLink] = useState(null);
  const [loading, setLoading] = useState(false);

  const resubmissionTags = {
    "Data mismatch":[
        "Full name issue",
        "Date of birth issue",
        "Address issue",
    ],
    "Additional Checks":[
        "Incorrect TIN",
    ],
    "Selfie issues":[
      "Different people",
    ],
    "Different docs":[
        "Different docs"
    ],
    "Spam":[
        "Spam"
    ],
    "KYC":[
        "Not enough data in source",
        "KYC skipped",
        "Data mismatch"
    ]
}

  const handleHide = () => {
    setLink(null);
    setShow(false);
  };

  const handleTagToggle = (tag) => {
    if (selectedTags.includes(tag)) {
        setSelectedTags((prevTags) => prevTags.filter((prevTag) => prevTag !== tag));
    } else {
        setSelectedTags((prevTags) => [...prevTags, tag]);
    }
};

  const onSubmit = () => {
      setLoading(true);
      const formattedValues = {
        customer: customer.uid,
        resubmission: {
          personalInformation: level.type === 'kyc' ? resubmissionFields.personalInformation : false,
          companyInformation: level.type === 'kyb' ? resubmissionFields.companyInformation : false,
          documents: resubmissionFields.documents,
          verifications: level.verifications && ( resubmissionFields.personalInformation || resubmissionFields.companyInformation )
        },
      };
      createResubmissionLink(formattedValues)
        .then((doc) => {
          showSnackbar("Resubmission requested successfully");
          setLink(window.location.origin + "/resubmission/" + level.type + "/" + doc.id);
          onComplete(formattedValues);
        })
        .catch((error) => {
          showSnackbar("Error creating resubmission link:" + error, "danger");
        })
        .finally(() => {
          setLoading(false);
        });
  };

  const createResubmissionLink = async (values) => {
    const formattedDocuments = values.resubmission.documents.map(doc => {
      const originalDoc = level.documents.find(d => d.name === doc);
      return {
        name: originalDoc.name,
        files: originalDoc.files // Assuming files need to be included as-is
      };
    });

    return addDoc(collection(db, "resubmissions"), {
      company: authUser.company.uid,
      companyName: authCompany.name,
      customer: values.customer,
      personalInformation: values.resubmission.personalInformation,
      companyInformation: values.resubmission.companyInformation,
      type: level.type,
      level: {
        documents: formattedDocuments,
        fields: (values.resubmission.personalInformation || values.resubmission.companyInformation) ? level.fields : [],
        verifications: (values.resubmission.personalInformation || values.resubmission.companyInformation) ? level.verifications : [],
      },
      message: selectedTags.join(", "),
      completedSteps: [],
      expirationDate: new Date(Timestamp.now().seconds * 1000 + 86400000),
      createdBy: authUser.uid,
      createdByName: authUser.displayName
    });
  };

  return (
    <Drawer anchor="right" open={show} onClose={handleHide}>
      <DialogTitle>Request Resubmission</DialogTitle>
      <ModalClose onClick={handleHide} />
      {link && 
      <Stack direction="column" p={2} spacing={1}>
        <Alert startDecorator={<CheckCircle />} color="success">The resubmission link has been created and sent to the customer's email.</Alert>
        <QRLink link={link} onClose={handleHide} />
      </Stack>
      }
      <Stack direction="column" spacing={2} p={2}>
        {!link && 
        <form>
          <AccordionGroup transition="0.2s ease">
          {Object.entries(resubmissionTags).map(([category, tags]) => (
              <Accordion key={category} eventKey={category}>
                  <AccordionSummary>
                      <Typography>
                          {category+" "}
                              {
                                  selectedTags.filter(tag => tags.includes(tag)).length > 0 &&
                                  <Chip color="primary">
                                      {selectedTags.filter(tag => tags.includes(tag)).length}
                                  </Chip>
                              }
                      </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                      <Stack direction="row" flexWrap="wrap" useFlexGap spacing={0.5}>
                          {tags.map((tag) => (
                              <Chip
                                  id={`tag-${tag.toLowerCase()}`}
                                  key={tag}
                                  value={tag}
                                  color="primary"
                                  variant={selectedTags && selectedTags.includes(tag) ? "solid" : "outlined"}
                                  aria-selected={selectedTags && selectedTags.includes(tag)}
                                  onClick={() => handleTagToggle(tag)}
                                  sx={(theme) => ({
                                      [`&[aria-pressed="true"]`]: {
                                          ...theme.variants.outlinedActive.neutral,
                                          borderColor: theme.vars.palette.neutral.outlinedHoverBorder,
                                      },
                                  })}
                              >
                                  {tag}
                              </Chip>
                          ))}
                      </Stack>
                  </AccordionDetails>
              </Accordion>
          ))}
          </AccordionGroup>
          <Stack direction="column" spacing={1}>
              <Button size="sm" color="primary" onClick={onSubmit} loading={loading} disabled={loading}>
                  Request Resubmission
              </Button>
          </Stack>
        </form>
        }
      </Stack>
    </Drawer>
  );
}
