import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../firebase/auth';

import { db } from '../../firebase/firebase';
import { doc, onSnapshot } from "firebase/firestore";

import BusinessProfileTransactions from './BusinessProfileTransactions';
import BusinessProfileVerification from './BusinessProfileVerification';
import Page from '../../layout/Page';
import TabListStyled from '../../components/TabListStyled';
import BusinessHeader from '../../layout/BusinessHeader';
import Header from '../../layout/Header';
import BusinessProfileNotes from './BusinessProfileNotes';

import Tab from '@mui/joy/Tab';
import Tabs from "@mui/joy/Tabs"
import TabPanel from "@mui/joy/TabPanel"
import { useSnackbar } from '../../utils/SnackbarContext';

const BusinessContext = createContext(null);

export function useBusiness(){
    return useContext(BusinessContext);
}

export function BusinessProfile() {
    
    const { authUser } = useAuth();

    const businessId = useParams().businessId;
    const [business, setBusiness] = useState();
    const showSnackbar = useSnackbar();
    
    const navigate = useNavigate();

    // fetch business document
    useEffect(() => {
        const docRef = doc(db, "companies", authUser.company.uid, "businesses", businessId);
        const unsubscribe = onSnapshot(docRef, (doc) => {
            if (!doc.exists()) {
                navigate("..", { relative: "path" });
                return;
            }
            setBusiness({
                uid: doc.id,
                ...doc.data(),
            });
        }, (error) => {
            showSnackbar("An error occurred: " + error, "danger");
        });

        return () => unsubscribe();
    }, [businessId, navigate, authUser.company.uid]);

    if(!business){
        return null;
    }
    return (
        <BusinessContext.Provider value={business}>
            <Page>
                <Tabs aria-label="Business Profile Tabs" defaultValue="verification" sx={{bgcolor: "transparent"}}>
                    <Header
                        customTitleContent={
                            <BusinessHeader/>
                        }
                        tabs={
                            <TabListStyled>
                                <Tab value="verification">Verification</Tab>
                                { business.status.status === 'approved' && 
                                    <Tab value="transactions">Transactions</Tab>
                                }
                                    <Tab value="notes">Notes</Tab>
                            </TabListStyled>
                        }
                    />
                    <TabPanel keepMounted sx={{px:0}} value="verification">
                        <BusinessProfileVerification />
                    </TabPanel>
                    <TabPanel keepMounted sx={{px:0}} value="transactions">
                        <BusinessProfileTransactions />
                    </TabPanel>
                    <TabPanel keepMounted sx={{px:0}} value="notes">
                        <BusinessProfileNotes />
                    </TabPanel>
                </Tabs>
            </Page>
        </BusinessContext.Provider>
    );
};