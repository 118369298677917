// SnackbarContext.js
import React, { createContext, useContext, useState, useCallback } from 'react';
import Snackbar from '@mui/joy/Snackbar';
import Typography from '@mui/joy/Typography';
import { ErrorOutline, InfoOutlined, TaskAlt, WarningAmber } from '@mui/icons-material';

const SnackbarContext = createContext();

const colors = {
    success: 'success',
    danger: 'danger',
    warning: 'warning',
    info: 'primary',
}

const icons = {
    success: <TaskAlt />,
    danger: <ErrorOutline />,
    warning: <WarningAmber />,
    info: <InfoOutlined />,
}

export const useSnackbar = () => {
  return useContext(SnackbarContext);
};

export const SnackbarProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info',
  });

  const showSnackbar = useCallback((message, severity = 'info') => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  }, []);

  const handleClose = () => {
    setSnackbar((prevState) => ({
      ...prevState,
      open: false,
    }));
  };

  return (
    <SnackbarContext.Provider value={showSnackbar}>
      {children}
      <Snackbar
        variant="soft"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        color={colors[snackbar.severity]}
        autoHideDuration={3000}
        open={snackbar.open}
        onClose={handleClose}
        startDecorator={icons[snackbar.severity]}
      >        
        <Typography level="body-sm" color={colors[snackbar.severity]}>
          {snackbar.message}
        </Typography>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
