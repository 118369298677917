const defaultFields = [
    "ID Number",
    "First Name",
    "Last Name",
    "Date of Birth",
    "Nationality",
    "Phone",
    "Email"
];

const defaultKYBFields = [
    "Email",
    "Company Name",
    "Country of Incorporation",
    "Registered Address",
    "Tax Number",
    "Incorporation no",
];

const optionalFields = [
    {name:"Place of Birth"},
    {name:"Address"},
    {name:"TIN"},
    {name:"Driving License Number"},
    {name:"Passport Number"},
    {name:"Residence Permit Number"},
    {name:"Work Permit Number"}
];

const optionalKYBFields = [
    {name:"Industry"},
    {name:"Company Phone"},
    {name:"Company Web Page"},
];

const documents = [
    {
        "name":"ID Card",
        "files":["Front","Back"]
    },
    {
        "name":"Passport",
        "files":["Passport"]
    },
    {
        "name":"Driving License",
        "files":["Front","Back"]
    },
    {
        "name":"Residence Permit",
        "files":["Residence Permit"]
    },
    {
        "name":"Proof of Address",
        "files":["Proof of Address"]
    },
    {
        "name":"Proof of Payment",
        "files":["Proof of Payment"]
    },
    {
        "name":"Bank Statement",
        "files":["Bank Statement"]
    },
    {
        "name":"Student ID",
        "files":["Front","Back"]
    },
    {
        "name":"Work Permit",
        "files":["Work Permit"]
    },
    {
        "name":"Tax Document",
        "files":["Tax Document"]
    }
];

const documentsKYB = [
    {
        "name":"Operating certificate",
        "files":["Operating certificate"]
    },
    {
        "name":"Trade registry documents",
        "files":["Trade registry documents"]
    },
    {
        "name":"Tax plate",
        "files":["Tax plate"]
    },
    {
        "name":"Proof of address",
        "files":["Proof of address"]
    },
    {
        "name":"Bank Statement",
        "files":["Bank Statement"]
    },
    {
        "name":"Signature Circulars",
        "files":["Signature Circulars"]
    },
    {
        "name":"Shareholders ID",
        "files":["Shareholders ID"]
    },
    {
        "name":"Directors ID",
        "files":["Directors ID"]
    },
    {
        "name":"Directors Passport",
        "files":["Directors Passport"]
    },
];

const verifications = [
    "email",
    "sms"
];

const transactionTypes = [
    "Transfer In",
    "Transfer Out",
    "Deposit",
    "Withdrawal",
    "Payment",
    "Crypto Payment",
    "Crypto Sales",
    "Crypto - Fiat Conversion",
    "Currency Exchange",
    "Gold Exchange",
    "Accomodation",
    "Car Rental",
]

const transactionCurrencies = [
    "USD", "EUR", "GBP", "AUD", "CAD", "CHF", "JPY", "TRY", "SGD", "ZAR", "PHP", "HKD", "BRL", "CNH", "NZD",
    "BTC", "ETH", "USDT", "USDC", "XRP", "SOL", "TRX", "BNB"
]

const plans = [
    {
        title: 'Basic',
        price: '$89',
        priceId: process.env.REACT_APP_STRIPE_BASIC_MONTHLY,
        description: 'Ideal for small businesses and individuals aiming to prevent fraud.',
        features: [
            '100 KYC Verifications',
            '20 KYB Verifications',
            '500 Transactions',
            'Up to 3 team members',
            'SMS / Email Verification',
            'Remote Verification Links',
        ]
    },
    {
        title: 'Plus',
        price: '$159',
        priceId: process.env.REACT_APP_STRIPE_PLUS_MONTHLY,
        description: 'Ideal for regulated businesses performing verification on a smaller scale.',
        highlight: false,
        features: [
            '250 KYC Verifications',
            '50 KYB Verifications',
            '1500 Transactions',
            'Up to 5 team members',
            'SMS / Email Verification',
            'Remote Verification Links',
        ]
    },
    {
        title: 'Pro',
        price: '$299',
        priceId: process.env.REACT_APP_STRIPE_PRO_MONTHLY,
        description: 'Ideal for large-scale businesses conducting verification at a higher volume.',
        features: [
            '500 KYC Verifications',
            '100 KYB Verifications',
            '5000 Transactions',
            'Up to 10 team members',
            'SMS / Email Verification',
            'Remote Verification Links',
        ]
    }
    
]

const benefits = [
    {
        title: 'Customer Onboarding',
        description: 'Easily onboard customers with a simple and secure process.',
    },
    {
        title: 'KYC Verification',
        description: 'Verify customer identities and reduce fraud.',
    },
    {
        title: 'Transaction Monitoring',
        description: 'Monitor transactions and detect suspicious activities.',
    },
    {
        title: 'Remote Verifications',
        description: 'Verify customers remotely with a secure process.',
    }
];


export { defaultFields, defaultKYBFields, optionalFields, optionalKYBFields, documents, documentsKYB, verifications, transactionTypes, transactionCurrencies, plans, benefits };