import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Stack from "@mui/joy/Stack";
import Avatar from "@mui/joy/Avatar";

export default function StatCard({title, value, icon=null, color="primary"}){
    return(
        <Card variant="outlined" orientation="horizontal"
            sx={{
                height: 1,
                bgcolor: "transparent",
            }}
        >
            <CardContent>
                <Stack
                direction={{xs:"row-reverse", sm:"row"}}
                justifyContent={{xs:"flex-end", sm:"space-between"}}
                spacing={2}
                alignItems="center">
                    <Box>
                        <Typography level="body-sm">{title}</Typography>
                        <Typography level="h4">{value!==null?value:"0"}</Typography>
                    </Box>
                    <Avatar variant="soft" color="primary" sx={{my: "auto", borderRadius: "sm"}}>
                        {icon && icon}
                    </Avatar>
                </Stack>
            </CardContent>
        </Card>
    );
}