import { useState } from "react";
import StatusTag from "./StatusTag";

import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import Check from "@mui/icons-material/Check";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { AccessTime, Clear, MoreHoriz, Refresh } from "@mui/icons-material";

export default function ApplicationCard({ title, name, check, status, onChangeStatus, children }) {
  // State to handle menu open/close
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (selectedStatus) => {
    if (selectedStatus) {
      onChangeStatus(name, selectedStatus); // Update status if a valid option is selected
    }
    setAnchorEl(null); // Close the menu
  };

  const statusOptions = [
    { value: "approved", label: "Approve" },
    { value: "rejected", label: "Reject" },
    { value: "resubmission", label: "Resubmission" },
  ];

  const icons = {
    "approved": <Check />,
    "pending": <AccessTime />,
    "rejected": <Clear />,
    "in progress": <MoreHoriz />,
    "resubmission": <Refresh />,
}

  return (
    <>
      <Box sx={{ m: 0 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Box sx={{ py: 0.5 }}>
            <Typography level="title-md">
              {title} <StatusTag status={status} />
            </Typography>
          </Box>
          {check && (
            <>
              <Button
                id="status-menu-button"
                aria-controls={Boolean(anchorEl) ? "status-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={Boolean(anchorEl) ? "true" : undefined}
                onClick={handleMenuOpen}
                size="sm"
                variant="soft"
                startDecorator={icons[status]}
                color={status === "approved" ? "success" : status === "rejected" ? "danger" : status === "resubmission" ? "neutral" : "primary"}
                endDecorator={<KeyboardArrowDown />}
                sx={{ width: 150, justifyContent: "space-between" }}
              >
                {statusOptions.find((option) => option.value === status)?.label ?? "Pending"}
              </Button>
              <Menu
                id="status-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => handleMenuClose(null)}
                aria-labelledby="status-menu-button"
                placement="bottom-end"
              >
                {statusOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    selected={option.value === status}
                    onClick={() => handleMenuClose(option.value)}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Menu>
            </>
          )}
        </Stack>
      </Box>
      <Card sx={{ marginTop: "10px!important" }} variant="outlined">
        <CardContent>{children}</CardContent>
      </Card>
    </>
  );
}
