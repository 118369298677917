import { useCompany } from "../firebase/auth";
import { Navigate, Outlet } from "react-router-dom";
import { Timestamp } from 'firebase/firestore';


function NoSubRoute() {
    const { authCompany } = useCompany();

    if(authCompany){
        if (authCompany?.subscription?.status === 'active') {
            return <Navigate to="/" />
        }else if(authCompany?.subscription?.status === 'trialing'){
            const trialEnd = new Date(authCompany.subscription.current_period_end);
            if(trialEnd <= Timestamp.now().seconds || authCompany.subscription.limits.trial===0){
                return <Outlet/>
            }
            return <Navigate to="/" />
        }
        else{
            return <Outlet/>
        }
    }
}

export default NoSubRoute;
