import Stack from "@mui/joy/Stack"
import Box from "@mui/joy/Box"
import Typography from "@mui/joy/Typography"
import Divider from "@mui/joy/Divider"
import Link from "@mui/joy/Link"

export default function FooterAuth() {
    return (
        <Box>
            <Divider />
            <Stack direction="row" justifyContent="space-between" mt={2}>
                <Box>
                    <Typography level="body-xs">Copyright &copy; TrueKYC {new Date().getFullYear()}</Typography>
                </Box>
                <Box>
                    <Stack direction="row" spacing={1}>
                        <Link href="https://truekyc.io/privacy" underline="none" color="primary" level="body-xs">
                                Privacy Policy 
                        </Link>
                        <Link href="https://truekyc.io/terms"  underline="none" color="primary" level="body-xs">
                                Terms &amp; Conditions
                        </Link>
                        <Link href="https://truekyc.io/dpa"  underline="none" color="primary" level="body-xs">
                                DPA
                        </Link>
                    </Stack>
                </Box>
            </Stack>
        </Box>
    )
}
