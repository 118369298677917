import { useEffect, useState } from 'react';
import { useAuth, useCompany } from '../../firebase/auth';

import { generateDateObject } from '../../utils/stats';

import AreaChart from '../../components/AreaChart';
import BarChart from '../../components/BarChart';
import StatCard from '../../components/StatCard';
import Activities from '../../components/Activities';
import Header from '../../layout/Header';
import Page from '../../layout/Page';
import TrialCard from '../../components/TrialCard';

import Stack from '@mui/joy/Stack';
import ToggleButtonGroup from '@mui/joy/ToggleButtonGroup';
import Button from '@mui/joy/Button';
import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';

import CurrencyExchange from '@mui/icons-material/CurrencyExchange';
import Groups from '@mui/icons-material/Groups';
import Business from '@mui/icons-material/Business';

export default function Home() {
    const { authUser } = useAuth();
    const { authCompany } = useCompany();
    const [applicationsData, setApplicationsData] = useState(null);
    const [transactionsData, setTransactionsData] = useState(null);
    const [customerCount, setCustomerCount] = useState(0);
    const [kybCount, setKybCount] = useState(0);
    const [transactionCount, setTransactionCount] = useState(0);
    const [activity, setActivity] = useState([]);
    const [applicationsPeriod, setApplicationsPeriod] = useState("weekly");
    const [transactionsPeriod, setTransactionsPeriod] = useState("weekly");
    const [applicationsSum, setApplicationsSum] = useState(0);
    const [transactionsSum, setTransactionsSum] = useState(0);

    useEffect(() => {
        let stats = authCompany.stats ? authCompany.stats : {};
        let applications = stats.applications ? stats.applications : {};

        const applicationsObj = generateDateObject();
        Object.keys(applicationsObj).forEach(key => {
            if (applications.hasOwnProperty(key)) {
                applicationsObj[key] = applications[key];
            }
        });

        let sortedKeys = Object.keys(applicationsObj).sort((a, b) => new Date(a) - new Date(b));
        let sortedValues = sortedKeys.map(key => applicationsObj[key]);

        let monthlyChartData = {
            labels: sortedKeys,
            datasets: [
                {
                    data: sortedValues,
                },
            ],
        };

        let weeklyChartData = {
            labels: sortedKeys.slice(-7),
            datasets: [
                {
                    data: sortedValues.slice(-7),
                },
            ],
        };

        setApplicationsData({
            monthly: monthlyChartData,
            weekly: weeklyChartData,
        });

        setApplicationsSum({
            monthly: sortedValues.reduce((a, b) => a + b, 0),
            weekly: sortedValues.slice(-7).reduce((a, b) => a + b, 0),
        });

        let transactions = stats.transactions ? stats.transactions : {};
        const transactionsObj = generateDateObject();
        Object.keys(transactionsObj).forEach(key => {
            if (transactions.hasOwnProperty(key)) {
                transactionsObj[key] = transactions[key];
            }
        });

        sortedKeys = Object.keys(transactionsObj).sort((a, b) => new Date(a) - new Date(b));
        sortedValues = sortedKeys.map(key => transactionsObj[key]);

        monthlyChartData = {
            labels: sortedKeys,
            datasets: [
                {
                    data: sortedValues,
                },
            ],
        };

        weeklyChartData = {
            labels: sortedKeys.slice(-7),
            datasets: [
                {
                    data: sortedValues.slice(-7),
                },
            ],
        };

        setTransactionsData({
            monthly: monthlyChartData,
            weekly: weeklyChartData,
        });

        setTransactionsSum({
            monthly: sortedValues.reduce((a, b) => a + b, 0),
            weekly: sortedValues.slice(-7).reduce((a, b) => a + b, 0),
        });

        setActivity(stats.activity);
        setCustomerCount(stats.applicationCount ? stats.applicationCount : 0);
        setTransactionCount(stats.transactionCount ? stats.transactionCount : 0);
        setKybCount(stats.kybCount ? stats.kybCount : 0);
    }, [authUser, authCompany]);

    const handleApplicationsPeriodChange = (event, newValue) => {
        if(newValue == null){
            return;
        }
        setApplicationsPeriod(newValue);
    };

    const handleTransactionsPeriodChange = (event, newValue) => {
        if(newValue == null){
            return;
        }
        setTransactionsPeriod(newValue);
    };

    return (
        <Page>
            <Header title="Dashboard" />
            <Box>
                <Grid container spacing={2} alignItems="stretch">
                    { authCompany.subscription.status === "trialing" &&
                        <Grid xs={12}>
                            <TrialCard name={authUser.displayName.split(" ")[0]} limit={authCompany.subscription?.limits?.trial}/>
                        </Grid>
                    }
                    <Grid xs={12} md={4}>
                        <StatCard title="Total Customers" value={customerCount} icon={<Groups />} />
                    </Grid>
                    <Grid xs={12} md={4}>
                        <StatCard title="Total Businesses" value={kybCount} icon={<Business />} color="success" />
                    </Grid>
                    <Grid xs={12} md={4}>
                        <StatCard title="Total Transactions" value={transactionCount} icon={<CurrencyExchange />} color="warning" />
                    </Grid>
                    <Grid xs={12} md={7}>
                        <Card variant="outlined" sx={{ p: 0 }}>
                            <CardContent>
                                <Stack direction={{xs:"column", lg:"row"}} spacing={2} p={2} justifyContent="space-between">
                                    <Box>
                                        <Typography level="title-lg">
                                            Applications
                                        </Typography>
                                        <Typography level="body-xs">
                                            This {applicationsPeriod === "monthly" ? "Month" : "Week"}
                                        </Typography>
                                        <Typography level="h2">
                                            {applicationsSum[applicationsPeriod]}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <ToggleButtonGroup
                                            value={applicationsPeriod}
                                            exclusive
                                            onChange={handleApplicationsPeriodChange}
                                            size="sm"
                                        >
                                            <Button size="sm" value="monthly">Monthly</Button>
                                            <Button size="sm" value="weekly">Weekly</Button>
                                        </ToggleButtonGroup>
                                    </Box>
                                </Stack>
                                {applicationsData && <AreaChart data={applicationsData[applicationsPeriod]} />}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid xs={12} md={5} alignItems="stretch">
                        <Card variant="outlined" sx={{ p: 0 }}>
                            <CardContent>
                                <Stack direction={{xs:"column", lg:"row"}} spacing={2} p={2} justifyContent="space-between">
                                    <Box>
                                        <Typography level="title-lg">
                                            Transactions
                                        </Typography>
                                        <Typography level="body-xs">
                                            This {transactionsPeriod === "monthly" ? "Month" : "Week"}
                                        </Typography>
                                        <Typography level="h2">
                                            {transactionsSum[transactionsPeriod]}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <ToggleButtonGroup
                                            value={transactionsPeriod}
                                            exclusive
                                            onChange={handleTransactionsPeriodChange}
                                            size="sm"
                                        >
                                            <Button size="sm" value="monthly">Monthly</Button>
                                            <Button size="sm" value="weekly">Weekly</Button>
                                        </ToggleButtonGroup>
                                    </Box>
                                </Stack>
                                {transactionsData && <BarChart data={transactionsData[transactionsPeriod]} />}
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid xs={12}>
                        <Activities activity={activity} />
                    </Grid>
                </Grid>
            </Box>
        </Page>
    );
}
