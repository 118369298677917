import React, { useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

const getGradient = (ctx, chartArea) => {
  const gradient = ctx.createLinearGradient(0, 0, 0, chartArea.bottom);
  gradient.addColorStop(0, 'rgba(68, 61, 246, 0.5)');
  gradient.addColorStop(1, 'rgba(68, 61, 246, 0)');
  return gradient;
};

export default function AreaChart({ data }) {
  const chartRef = useRef(null);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
          tickLength: 0,
        },
        ticks: {
          color: "#00000050", // Adjust the color if needed
          font: {
            size: 12, // Adjust the size if needed
          },
          padding: 10
        }
      },
      y: {
        min: 0,
        grid: {
          tickLength: 0,
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          callback: function (value) { if (value % 1 === 0 && value > 0) { return value; } },
          color: "#00000050",
          padding: 10,
        }
      }
    },
    legend: {
      display: false
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        backgroundColor: "rgb(255,255,255)",
        bodyColor: "#858796",
        titleFont: {
          size: 14,
        },
        bodyFont: {
          size: 14,
        },
        titleMarginBottom: 10,
        titleColor: "#6e707e",
        borderColor: "#dddfeb",
        borderWidth: 1,
        padding: {
          x: 15,
          y: 15,
        },
        displayColors: false,
        intersect: false,
        mode: "index",
        caretPadding: 10,
      }
    },
  };

  const chartData = {
    ...data,
    datasets: data.datasets.map(dataset => ({
      ...dataset,
      backgroundColor: (context) => {
        const chart = context.chart;
        const { ctx, chartArea } = chart;
        if (!chartArea) {
          return null;
        }
        return getGradient(ctx, chartArea);
      },
      borderColor: "#443DF6",
      pointBorderColor: "transparent",
      pointBackgroundColor: "transparent",
      hoverRadius: 5,
      fill: true,
      tension: 0,
    })),
  };

  return (
    <div style={{ position: 'relative', minHeight: '200px', maxHeight: '200px'}}>
      <Line ref={chartRef} data={chartData} options={options} />
    </div>
  );
}
