import { useState, useEffect } from 'react';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation to get state
import { db } from '../../firebase/firebase';
import { useAuth } from '../../firebase/auth';

import Page from '../../layout/Page';
import Header from '../../layout/Header';
import CustomModal from '../../components/CustomModal';
import TabListStyled from '../../components/TabListStyled';
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import ModalOverflow from "@mui/joy/ModalOverflow";
import ModalClose from "@mui/joy/ModalClose";
import DialogTitle from "@mui/joy/DialogTitle";
import Button from "@mui/joy/Button";
import Tab from '@mui/joy/Tab';
import Tabs from "@mui/joy/Tabs";
import TabPanel from "@mui/joy/TabPanel";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import Add from '@mui/icons-material/Add';

import LevelsKYC from './LevelsKYC';
import LevelsKYB from './LevelsKYB';
import LevelKYCForm from '../../forms/levels/LevelKYCForm';
import LevelKYBForm from '../../forms/levels/LevelKYBForm';
import { useSnackbar } from '../../utils/SnackbarContext';

export default function Levels() {
    const { authUser } = useAuth();
    const navigate = useNavigate();
    const location = useLocation(); // Use location to access state
    const showSnackbar = useSnackbar();

    const [anchorEl, setAnchorEl] = useState(null);
    const [isCreating, setIsCreating] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editLevel, setEditLevel] = useState(null);
    const [levelType, setLevelType] = useState('kyc');
    const [levelToDelete, setLevelToDelete] = useState();
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        // Check location state and open modal if create=true is set
        const { state } = location;
        if (state && state.create) {
            setLevelType(state.type || 'kyc');
            setIsCreating(true);
        }
    }, [location.state]);

    const handleMenuClick = (event) => setAnchorEl(event.currentTarget);
    const handleMenuClose = () => setAnchorEl(null);

    const handleMenuSelect = (type) => {
        setLevelType(type);
        setIsCreating(true);
        setAnchorEl(null);
        // Pass state to open the create modal
        navigate('/levels', { state: { create: true, type } });
    };

    const handleEditLevel = async (levelId) => {
        try {
            const docRef = doc(db, 'companies', authUser.company.uid, 'levels', levelId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setEditLevel({
                    id: docSnap.id,
                    ...docSnap.data(),
                });
                setLevelType(docSnap.data().type);
                setIsEditing(true);
            } else {
                showSnackbar('Level not found.', 'danger');
            }
        } catch (error) {
            showSnackbar('An error occurred: ' + error.message, 'danger');
        }
    };

    const deleteLevel = async (levelId) => {
        const levelRef = doc(db, "companies", authUser.company.uid, "levels", levelId);
        try {
            await updateDoc(levelRef, { isDeleted: true });
            showSnackbar("Level deleted successfully.", "success");
        } catch (error) {
            showSnackbar("Error deleting level: " + error.message, "danger");
        }
    };

    const handleDeleteConfirmation = () => {
        deleteLevel(levelToDelete);
        setLevelToDelete();
        setRefresh(refresh + 1);
    };

    const handleModalClose = () => {
        setIsCreating(false);
        setIsEditing(false);
        setEditLevel(null);
        // Reset state when modal is closed
        navigate('/levels', { replace: true, state: {} });
    };

    const handleSuccess = () => {
        handleModalClose();
        setRefresh(refresh + 1);
    };

    return (
        <Page>
            <Tabs aria-label="Levels Tabs" defaultValue="kyc" sx={{ bgcolor: "transparent" }}>
                <Header
                    title="Levels"
                    tabs={
                        <TabListStyled>
                            <Tab value="kyc">KYC</Tab>
                            <Tab value="kyb">KYB</Tab>
                        </TabListStyled>
                    }
                    controls={
                        <>
                            <Button size="sm" onClick={handleMenuClick} startDecorator={<Add />}>
                                Create New
                            </Button>
                            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                                <MenuItem onClick={() => handleMenuSelect('kyc')}>KYC Level</MenuItem>
                                <MenuItem onClick={() => handleMenuSelect('kyb')}>KYB Level</MenuItem>
                            </Menu>
                        </>
                    }
                />
                <TabPanel keepMounted sx={{ px: 0 }} value="kyc">
                    <LevelsKYC key={refresh} setLevelToDelete={setLevelToDelete} handleLevelEdit={handleEditLevel} />
                </TabPanel>
                <TabPanel keepMounted sx={{ px: 0 }} value="kyb">
                    <LevelsKYB key={refresh} setLevelToDelete={setLevelToDelete} handleLevelEdit={handleEditLevel} />
                </TabPanel>
            </Tabs>

            {/* Create/Edit Modal */}
            <Modal open={isCreating || isEditing} onClose={handleModalClose}>
                <ModalOverflow>
                    <ModalDialog layout="center" sx={{ maxWidth: 768 + "px" }}>
                        <ModalClose />
                        {isCreating ? (
                            <DialogTitle>Create {levelType.toUpperCase()} Level</DialogTitle>
                        ) : (
                            <DialogTitle>Edit {editLevel?.type.toUpperCase()} Level</DialogTitle>
                        )}
                        {levelType === 'kyc' ? (
                            <LevelKYCForm onSuccess={handleSuccess} level={editLevel ? editLevel : null} />
                        ) : (
                            <LevelKYBForm onSuccess={handleSuccess} level={editLevel ? editLevel : null} />
                        )}
                    </ModalDialog>
                </ModalOverflow>
            </Modal>

            <CustomModal.Delete
                open={!!levelToDelete}
                title="Confirm Deletion"
                message="Are you sure you want to delete this level?"
                onClose={() => setLevelToDelete(null)}
                handleDelete={handleDeleteConfirmation}
            />
        </Page>
    );
}
