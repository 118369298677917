import { useFormik } from "formik";
import * as Yup from 'yup';
import { doc, setDoc, addDoc, collection } from "firebase/firestore";
import { db } from '../../firebase/firebase';
import { useAuth } from "../../firebase/auth";
import { documentsKYB as documents, defaultKYBFields as defaultFields, optionalKYBFields as optionalFields } from "../../utils/constants";

import Button from "@mui/joy/Button";
import Checkbox from "@mui/joy/Checkbox";
import Grid from "@mui/joy/Grid";
import Input from "@mui/joy/Input";
import Stack from "@mui/joy/Stack";
import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";

import Add from "@mui/icons-material/Add";
import { useSnackbar } from "../../utils/SnackbarContext";
import { Delete } from "@mui/icons-material";

export default function LevelKYBForm({ level, onSuccess }) {
    const { authUser } = useAuth();
    const showSnackbar = useSnackbar();
    const levelType = "kyb";

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Level Name is required'),
        fields: Yup.array().of(
            Yup.object().shape({
                name: Yup.string().required('Field name is required'),
            })
        ),
        documents: Yup.array().of(Yup.string()).min(1, 'At least one document is required'),
        verifications: Yup.array().of(Yup.string()).required(),
    });

    const formik = useFormik({
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: {
            name: level?.name || "",
            fields: level?.fields.filter(field => !optionalFields.some(opt => opt.name === field.name)) || [],
            optionalFields: level?.fields.filter(field => optionalFields.some(opt => opt.name === field.name)) || [],
            documents: level?.documents.map(doc => doc.name) || [],
            verifications: level?.verifications || [],
        },
        validationSchema,
        onSubmit: async (values) => {
            values.fields = [...values.fields, ...values.optionalFields];
            values.type = levelType;
            values.isDeleted = false;
            delete values.optionalFields;

            // Map selected document names to their corresponding files
            values.documents = values.documents.map(documentName => {
                const document = documents.find(doc => doc.name === documentName);
                return { name: documentName, files: document.files };
            });

            try {
                if (!!level) {
                    const docRef = doc(db, "companies", authUser.company.uid, "levels", level?.id);
                    await setDoc(docRef, values, { merge: true });
                    showSnackbar("Level updated successfully", "success");
                    onSuccess();
                } else {
                    const newLevelRef = collection(db, "companies", authUser.company.uid, "levels");
                    await addDoc(newLevelRef, values);
                    showSnackbar("Level created successfully", "success");
                    onSuccess();
                }
            } catch (error) {
                showSnackbar("An error occurred: " + error, "danger");
            }
        },
    });

    const handleAddField = () => {
        formik.setFieldValue("fields", [...formik.values.fields, { name: "" }]);
    };

    const handleDeleteField = index => {
        const newFields = [...formik.values.fields];
        newFields.splice(index, 1);
        formik.setFieldValue("fields", newFields);
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
                <FormLabel>Level Name</FormLabel>
                <FormControl error={formik.touched.name && !!formik.errors.name}>
                    <Input 
                        name="name"
                        type="text"
                        size="sm"
                        placeholder="Enter level name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                    />
                    {formik.touched.name && formik.errors.name && (
                        <FormHelperText>{formik.errors.name}</FormHelperText>
                    )}
                </FormControl>
                <FormLabel>Company Information</FormLabel>
                <Grid container spacing={0.5}>
                    {defaultFields.map((field) => (
                        <Grid key={field} xs={12} sm={6} md={4}>
                            <Checkbox 
                                size="sm" 
                                label={field} 
                                name={field} 
                                defaultChecked 
                                disabled 
                            />
                        </Grid>
                    ))}
                    {optionalFields.map((field) => (
                        <Grid key={field.name} xs={12} sm={6} md={4}>
                            <Checkbox 
                                size="sm" 
                                name={`optionalFields[${field.name}]`} 
                                label={field.name}
                                onChange={() => {
                                    const updatedOptionalFields = formik.values.optionalFields.some(f => f.name === field.name)
                                        ? formik.values.optionalFields.filter(f => f.name !== field.name)
                                        : [...formik.values.optionalFields, { name: field.name }];
                                    formik.setFieldValue("optionalFields", updatedOptionalFields);
                                }}
                                defaultChecked={formik.values.optionalFields.some(f => f.name === field.name)}
                            />
                        </Grid>
                    ))}
                    {formik.values.fields.map((field, index) => (
                        <Grid key={index} xs={12}>
                            <Grid container spacing={1} sx={{ width: {xs:1, md:0.5} }}>
                                <Grid xs>
                                    <Input
                                        type="text"
                                        size="sm"
                                        placeholder="Field name"
                                        value={field.name}
                                        onChange={(e) => formik.setFieldValue(`fields[${index}].name`, e.target.value)}
                                        name={`fields[${index}].name`}
                                    />
                                    {formik.touched.fields?.[index]?.name && formik.errors.fields?.[index]?.name && (
                                        <FormHelperText>{formik.errors.fields[index].name}</FormHelperText>
                                    )}
                                </Grid>
                                <Grid xs={1}>
                                    <Button 
                                        variant="plain"
                                        size="sm"
                                        color="danger"
                                        onClick={() => handleDeleteField(index)}
                                    >
                                        <Delete />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                    <Grid xs={12}>
                        <Button
                            size="xs"
                            variant="plain"
                            onClick={handleAddField}
                            startDecorator={<Add />}
                        >
                            Add Custom Field
                        </Button>
                    </Grid>
                </Grid>
                <FormLabel>Required Documents</FormLabel>
                <FormControl error={formik.touched.documents && !!formik.errors.documents}>
                    <Grid container spacing={0.5}>
                        {documents.map((document) => (
                            <Grid key={document.name} xs={12} sm={6} md={4}>
                                <Checkbox 
                                    size="sm" 
                                    label={document.name} 
                                    name={`documents.${document.name}`}
                                    onChange={() => {
                                        const updatedDocuments = formik.values.documents.includes(document.name)
                                            ? formik.values.documents.filter(d => d !== document.name)
                                            : [...formik.values.documents, document.name];
                                        formik.setFieldValue("documents", updatedDocuments);
                                    }}
                                    defaultChecked={formik.values.documents.includes(document.name)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    {formik.touched.documents && formik.errors.documents && (
                        <FormHelperText>{formik.errors.documents}</FormHelperText>
                    )}
                </FormControl>
                <FormLabel>Verification Methods</FormLabel>
                <Grid container spacing={0.5}>
                    <Grid key="email" xs={12}>
                        <Checkbox 
                            size="sm" 
                            name="verifications.email" 
                            label="Email Verification" 
                            onChange={() => {
                                const updatedVerifications = formik.values.verifications.includes("email")
                                    ? formik.values.verifications.filter(v => v !== "email")
                                    : [...formik.values.verifications, "email"];
                                formik.setFieldValue("verifications", updatedVerifications);
                            }}
                            defaultChecked={formik.values.verifications.includes("email")}
                        />
                    </Grid>
                </Grid>
                <Button size="sm" variant="solid" color="primary" type="submit" loading={formik.isSubmitting} disabled={formik.isSubmitting}>Save</Button>
            </Stack>
        </form>
    );
}
