import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getPasswordResetOTP, verifyPasswordResetOTP } from '../../firebase/register';
import { Link as RouterLink } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Box from "@mui/joy/Box";
import Link from "@mui/joy/Link";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import FormHelperText from "@mui/joy/FormHelperText";
import Input from "@mui/joy/Input";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import Typography from "@mui/joy/Typography";

import { useSnackbar } from '../../utils/SnackbarContext';
import PasswordRequirements from '../../components/PasswordRequirements';

const validationSchema = Yup.object({
    password: Yup.string().required('Required')
        .min(8)
        .max(20)
        .matches(/(?=.*[A-Z])/)
        .matches(/(?=.*[a-z])/)
        .matches(/(?=.*[0-9])/),
    passwordConfirm: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Required'),
    code: Yup.string().when('otpSent', {
        is: true,
        then: Yup.string().required('Required')
    })
});

export default function PasswordResetForm({link}) {

    const showSnackbar = useSnackbar();
    const [otpSent, setOtpSent] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const formik = useFormik({
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: {
            password: '',
            passwordConfirm: '',
            code: ''
        },
        validationSchema,
        onSubmit: async (values) => {
            if (!otpSent) {
                setLoading(true);
                const data = {
                    link: link
                }
                getPasswordResetOTP(data)
                .then(response => response.json())
                .then((result) => {
                    if(result.success){
                        setOtpSent(true);
                    }else{
                        showSnackbar(result.message, "danger");
                    }
                })
                .catch(error => {
                    showSnackbar(error.message, "danger");
                })
                .finally(() => {
                    setLoading(false);
                });
            } else {
                setLoading(true);
                const data = {
                    password: values.password,
                    code: values.code,
                    link: link
                }
                verifyPasswordResetOTP(data)
                .then(response => response.json())
                .then((result) => {
                    if(result.success){
                        showSnackbar('Password reset successful');
                        navigate('/login');
                    }else{
                        showSnackbar(result.message, "danger");
                    }
                })
                .catch(error => {
                    showSnackbar(error.message, "danger");
                })
                .finally(() => {
                    setLoading(false);
                });
            }
        }
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            {!otpSent ? (
                <Stack direction="column" spacing={1}>
                    <Typography level="h2">Reset your password</Typography>
                    <FormControl error={formik.touched.password && !!formik.errors.password}>
                        <FormLabel>New Password</FormLabel>
                        <Input
                            type="password"
                            name="password"
                            onChange={formik.handleChange}
                            value={formik.values.password}
                        />
                    </FormControl>
                    <FormControl error={formik.touched.passwordConfirm && !!formik.errors.passwordConfirm}>
                        <FormLabel>Confirm Password</FormLabel>
                        <Input
                            type="password"
                            name="passwordConfirm"
                            onChange={formik.handleChange}
                            value={formik.values.passwordConfirm}
                        />
                        {formik.touched.passwordConfirm && formik.errors.passwordConfirm ? (
                            <FormHelperText>{formik.errors.passwordConfirm}</FormHelperText>
                        ) : null}
                    </FormControl>
                    <PasswordRequirements password={formik.values.password} /> {/* Include PasswordRequirements component */}
                    <Button size="sm" type="submit" loading={loading} disabled={loading}>
                        Change Password
                    </Button>
                    <Box textAlign={"end"}>
                        <RouterLink to="/login">
                            <Link level="body-sm">Remember your password? Log In</Link>
                        </RouterLink>
                    </Box>
                </Stack>
            ) : (
                <Stack direction="column" spacing={1}>
                    <Typography level="h2">Verify your phone number</Typography>
                    <Typography level="body">Please verify the OTP we sent to your phone number</Typography>
                    <FormControl error={formik.touched.code && !!formik.errors.code}>
                        <FormLabel>Code</FormLabel>
                        <Input
                            type="text"
                            name="code"
                            onChange={formik.handleChange}
                            value={formik.values.code}
                        />
                        {formik.touched.code && formik.errors.code ? (
                            <FormHelperText>{formik.errors.code}</FormHelperText>
                        ) : null}
                    </FormControl>
                    <Button size="sm" type="submit" loading={loading} disabled={loading}>
                        Verify
                    </Button>
                </Stack>
            )}
        </form>
    );
}
