import { useState, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import Box from "@mui/joy/Box";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import FormHelperText from "@mui/joy/FormHelperText";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import FileInput from "../../../components/form/FileInput";

const buildValidationSchema = (documents) => {
    const documentSchemas = documents.reduce((acc, document) => {
        acc[document] = Yup.string().required('Required');
        return acc;
    }, {});
    return Yup.object().shape(documentSchemas);
};

export default function LinkKYBDocumentForm({ useLinkKYB }) {
    const { level, handleSubmit, files, setFiles } = useLinkKYB();
    const documents = level.documents;
    const [documentStep, setDocumentStep] = useState(0);
    const inputRefs = useRef([]);

    const validationSchema = buildValidationSchema(documents);

    const formik = useFormik({
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: documents.reduce((acc, document) => {
            document.files.forEach(file => {
                acc[`${document.name}_${file.name}`] = '';
            });
            return acc;
        }, {}),
        validationSchema,
        onSubmit: async (values) => {
            if (documentStep === documents.length - 1) {
                handleSubmit();
            } else {
                setDocumentStep((prevStep) => prevStep + 1);
                formik.setErrors({});
            }
        },
    });

    const handleFileChange = (event, documentName, fileName) => {
        const newFiles = { ...files };
        newFiles[`${documentName}_${fileName}`] = event.target.files[0];
        setFiles(newFiles);
        formik.setFieldValue(`${documentName}_${fileName}`, event.target.files[0].name);
    };

    return (
        <form onSubmit={(e) => {
            e.preventDefault();
            formik.validateForm().then((errors) => {
                if (!errors[`${documents[documentStep].name}_${documents[documentStep].files[0].name}`]) {
                    if (documentStep === documents.length - 1) {
                        handleSubmit();
                    } else {
                        setDocumentStep((prevStep) => prevStep + 1);
                        formik.setErrors({});
                    }
                } else {
                    formik.handleSubmit(e);
                }
            });
        }}>
            <Typography level="h3" mb={2}>
                Upload Documents
            </Typography>
            <Stack direction="column" spacing={2}>
                <Box>
                    {documents.map((document, index) => (
                        <div key={index} style={{ display: index === documentStep ? "block" : "none" }}>
                            <Typography level="h4" mb={1}>{document.name}</Typography>
                            {document.files.map((file, fileIndex) => (
                                <FormControl key={fileIndex} error={!!formik.errors[`${document.name}_${file}`]}>
                                    <FormLabel>{file}</FormLabel>
                                    <FileInput
                                        name={`${document.name}_${file}`}
                                        onChange={(e) => handleFileChange(e, document.name, file)}
                                        formik={formik}
                                        ref={(el) => (inputRefs.current[fileIndex] = el)}
                                    />
                                    {formik.errors[`${document.name}_${file}`] && (
                                        <FormHelperText>{formik.errors[`${document.name}_${file}`]}</FormHelperText>
                                    )}
                                </FormControl>
                            ))}
                        </div>
                    ))}
                </Box>
                <Button size="sm" type="submit">
                    {documentStep === documents.length - 1 ? "Submit" : "Next"}
                </Button>
            </Stack>
        </form>
    );
}
