import LayoutAuth from '../../layout/LayoutAuth';
import MemberRegistrationForm from '../../forms/members/MemberRegistrationForm';

import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';

export default function MemberRegistration() {

    return (
        <LayoutAuth>
            <Box mb={1}>
                <Typography level="h2">Welcome to TrueKYC</Typography>
            </Box>
            <MemberRegistrationForm/>
        </LayoutAuth>
    );
}
