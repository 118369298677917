import { useState, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth, useCompany } from '../../firebase/auth';
import { createCustomerForCompany } from '../../firebase/firestore';
import { uploadFile } from '../../firebase/storage';
import { Timestamp } from 'firebase/firestore';
import CustomModal from '../../components/CustomModal';
import PhoneInput from '../../components/form/PhoneInput';
import FileInput from '../../components/form/FileInput';
import CountryInput from '../../components/form/CountryInput';
import SMSVerificationForm from '../verification/SMSVerificationForm';
import EmailVerificationForm from '../verification/EmailVerificationForm';

import Stack from '@mui/joy/Stack';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import FormLabel from '@mui/joy/FormLabel';
import FormControl from '@mui/joy/FormControl';
import FormHelperText from '@mui/joy/FormHelperText';
import Typography from '@mui/joy/Typography';
import { useSnackbar } from '../../utils/SnackbarContext';

const buildValidationSchema = (level) => {
    const fieldSchemas = {
        idNumber: Yup.string().required('Required'),
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        nationality: Yup.string().required('Required'),
        dateofbirth: Yup.date().required('Required'),
        phone: Yup.string().required('Required'),
        email: Yup.string().email('Invalid email').required('Required'),
        fields: Yup.object().shape(level.fields.reduce((acc, field) => ({
            ...acc,
            [field.name]: Yup.string().required(`${field.name} is required`)
        }), {})),
        documents: Yup.object().shape(level.documents.reduce((acc, document) => ({
            ...acc,
            [document.name]: Yup.object().shape(document.files.reduce((fileAcc, file) => ({
                ...fileAcc,
                [file]: Yup.string().required(`${file} is required`)
            }), {}))
        }), {}),
        )
    };

    return Yup.object().shape(fieldSchemas);
};

export default function CustomerCreateForm({ level, onComplete }) {
    const { authUser } = useAuth();
    const { authCompany } = useCompany();
    const showSnackbar = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState({});
    const [showSMSModal, setShowSMSModal] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);

    // Move SMS and email verification state inside the component
    const [isSMSVerified, setIsSMSVerified] = useState(false);
    const [isEmailVerified, setIsEmailVerified] = useState(false);

    const validationSchema = useMemo(() => buildValidationSchema(level), [level]);

    const formik = useFormik({
        validateOnBlur: false,
        validateOnChange: false,
        initialValues: {
            idNumber: '',
            firstName: '',
            lastName: '',
            nationality: '',
            dateofbirth: '',
            phoneVerified: false,  // Initial state
            emailVerified: false,  // Initial state
            phone: '',
            email: '',
            fields: level.fields.reduce((acc, field) => ({ ...acc, [field.name]: '' }), {}),
            documents: level.documents.reduce((acc, document) => ({
                ...acc,
                [document.name]: document.files.reduce((fileAcc, file) => ({ ...fileAcc, [file]: '' }), {})
            }), {}), // Store file names
        },
        validationSchema,
        onSubmit: (values) => {
            if (authCompany?.subscription?.limits?.customers <= 0) {
                setLoading(false);
                showSnackbar("You have reached the limit of KYC verifications allowed in your plan", "danger");
                return;
            }
            if (level.verifications && level.verifications.includes("sms") && !isSMSVerified) {
                setShowSMSModal(true);
            } else if (level.verifications && level.verifications.includes("email") && !isEmailVerified) {
                setShowEmailModal(true);
            } else {
                handleCreate(values);
            }
        },
    });

    const handleFile = (event) => {
        const name = event.target.name;
        const file = event.target.files[0];
        const fileName = event.target.files[0].name;
        const documentName = name.split('.')[1];
        const fileKey = name.split('.')[2];

        setFiles({
            ...files,
            [documentName]: {
                ...files[documentName],
                [fileKey]: file,
            }
        });

        formik.setFieldValue(name, fileName);
    };

    const handleSMSVerified = () => {
        setShowSMSModal(false);
        setIsSMSVerified(true);  // Update state
        formik.setFieldValue('phoneVerified', true);  // Update the formik value
        formik.handleSubmit();
    };

    const handleEmailVerified = () => {
        setIsEmailVerified(true);  // Update state
        setShowEmailModal(false);
        formik.setFieldValue('emailVerified', true);  // Update the formik value
        formik.handleSubmit();
    };

    const handleCreate = async (customer) => {
        setLoading(true);

        // Prepare customer fields
        if (customer.fields) {
            customer.fields = Object.entries(customer.fields).map(([name, value]) => ({ name, value }));
        } else {
            customer.fields = [];
        }
        customer.createdBy = authUser.uid;
        customer.createdByName = authUser.displayName;

        let uploadedFiles = [];

        // Upload files and collect URLs
        for (const [documentName, fileGroup] of Object.entries(files)) {
            for (const [fileKey, file] of Object.entries(fileGroup)) {
                const fileBucket = `companies/${authUser.company.uid}/customers/${customer.idNumber}`;
                const fileName = documentName === fileKey ? `${documentName}` : `${documentName}_${fileKey}`;
                const fileBucketPath = await uploadFile(file, fileBucket, fileName);
                if (!fileBucketPath) {
                    showSnackbar("Failed to upload file", "error");
                    setLoading(false);
                    return;
                }
                uploadedFiles.push({ name: documentName, file: fileKey, url: fileBucketPath });
            }
        }

        // Build customer documents structure
        const customerDocuments = uploadedFiles.reduce((acc, { name, file, url }) => {
            const existingDoc = acc.find(doc => doc.name === name);
            const fileObj = { name: file, url: url };

            if (existingDoc) {
                existingDoc.files.push(fileObj);
            } else {
                acc.push({ name, files: [fileObj] });
            }

            return acc;
        }, []);

        // Prepare final customer object with documents and status
        const newCustomer = {
            ...customer,
            documents: customerDocuments,
            status: {
                status: 'pending',
                fields: 'pending',
                documents: customerDocuments.reduce((acc, doc) => ({ ...acc, [doc.name]: 'pending' }), {}),
                verifications: {
                    sms: isSMSVerified,
                    email: isEmailVerified
                }
            },
            createdAt: Timestamp.now(),
            updatedAt: Timestamp.now(),
            level: level
        };

        try {
            // Create customer in Firestore with all data
            await createCustomerForCompany(authUser.company.uid, newCustomer, level);
            showSnackbar("Customer created successfully", "success");
            onComplete();
        } catch (e) {
            console.error("Error creating customer:", e);
            showSnackbar("Failed to create customer", "error");
        } finally {
            setLoading(false);
        }
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
                <Typography level="title-md">Personal Information</Typography>
                <FormControl error={!!formik.errors.idNumber} sx={{ flexGrow: 1 }}>
                    <FormLabel>ID Number</FormLabel>
                    <Input
                        type="text"
                        name="idNumber"
                        value={formik.values.idNumber}
                        onChange={formik.handleChange}
                    />
                    {formik.errors.idNumber && (
                        <FormHelperText>{formik.errors.idNumber}</FormHelperText>
                    )}
                </FormControl>
                <FormControl error={!!formik.errors.firstName} sx={{ flexGrow: 1 }}>
                    <FormLabel>First Name</FormLabel>
                    <Input
                        type="text"
                        name="firstName"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                    />
                    {formik.errors.firstName && (
                        <FormHelperText>{formik.errors.firstName}</FormHelperText>
                    )}
                </FormControl>
                <FormControl error={!!formik.errors.lastName} sx={{ flexGrow: 1 }}>
                    <FormLabel>Last Name</FormLabel>
                    <Input
                        type="text"
                        name="lastName"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                    />
                    {formik.errors.lastName && (
                        <FormHelperText>{formik.errors.lastName}</FormHelperText>
                    )}
                </FormControl>
                <FormControl error={!!formik.errors.nationality} sx={{ flexGrow: 1 }}>
                    <FormLabel>Nationality</FormLabel>
                    <CountryInput
                        name="nationality"
                        value={formik.values.nationality}
                        setFieldValue={formik.setFieldValue}
                        formik={formik}
                    />
                    {formik.errors.nationality && (
                        <FormHelperText>{formik.errors.nationality}</FormHelperText>
                    )}
                </FormControl>
                <FormControl error={!!formik.errors.dateofbirth} sx={{ flexGrow: 1 }}>
                    <FormLabel>Date Of Birth</FormLabel>
                    <Input
                        type="date"
                        name="dateofbirth"
                        value={formik.values.dateofbirth}
                        onChange={formik.handleChange}
                    />
                    {formik.errors.dateofbirth && (
                        <FormHelperText>{formik.errors.dateofbirth}</FormHelperText>
                    )}
                </FormControl>
                <FormControl error={!!formik.errors.phone} sx={{ flexGrow: 1 }}>
                    <FormLabel>Phone Number</FormLabel>
                    <PhoneInput
                        name="phone"
                        setFieldValue={formik.setFieldValue}
                        formik={formik}
                    />
                    {formik.errors.phone && (
                        <FormHelperText>{formik.errors.phone}</FormHelperText>
                    )}
                </FormControl>
                <FormControl error={!!formik.errors.email} sx={{ flexGrow: 1 }}>
                    <FormLabel>Email</FormLabel>
                    <Input
                        type="text"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                    />
                    {formik.errors.email && (
                        <FormHelperText>{formik.errors.email}</FormHelperText>
                    )}
                </FormControl>
                {level.fields && level.fields.length > 0 &&
                    <>
                        <Typography level="title-md">Additional Information</Typography>
                        <Stack direction="column" spacing={1}>
                            {level.fields.map((field) => (
                                <FormControl
                                    key={field.name}
                                    error={!!formik.errors.fields?.[field.name]}
                                    sx={{ flexGrow: 1 }}
                                >
                                    <FormLabel>{field.name}</FormLabel>
                                    <Input
                                        type="text"
                                        name={`fields.${field.name}`}
                                        value={formik.values.fields?.[field.name]}
                                        onChange={formik.handleChange}
                                    />
                                    {formik.errors.fields?.[field.name] && (
                                        <FormHelperText>{formik.errors.fields?.[field.name]}</FormHelperText>
                                    )}
                                </FormControl>
                            ))}
                        </Stack>
                    </>
                }
                <Typography level="title-md">Documents</Typography>
                <Stack spacing={2}>
                    {level.documents.map((document) =>
                        <FormControl
                            key={document.name}
                            error={!!formik.errors.documents?.[document.name]}
                        >
                            <FormLabel>{document.name}</FormLabel>
                            {
                            document.files.map((file) => 
                                <div key={file}>
                                    { file !== document.name && <FormLabel>{file}</FormLabel>}
                                    <FileInput
                                        name={`documents.${document.name}.${file}`}
                                        formik={formik}
                                        onChange={handleFile}
                                    />
                                    {formik.errors.documents?.[document.name]?.[file] && (
                                        <FormHelperText>{formik.errors.documents?.[document.name]?.[file]}</FormHelperText>
                                    )}
                                </div>
                            )}
                        </FormControl>
                    )}
                </Stack>
                <Button size="sm" variant="solid" color="primary" type="submit" loading={loading} disabled={loading}>Submit</Button>
            </Stack>
            <CustomModal open={showSMSModal}>
                <SMSVerificationForm phoneNumber={formik.values.phone} onComplete={handleSMSVerified} />
            </CustomModal>
            <CustomModal open={showEmailModal}>
                <EmailVerificationForm email={formik.values.email} onComplete={handleEmailVerified} />
            </CustomModal>
        </form>
    );
}