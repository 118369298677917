import { useFormik } from "formik";
import * as Yup from "yup";
import CountryInput from '../../../components/form/CountryInput';

import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import FormHelperText from "@mui/joy/FormHelperText";
import Typography from "@mui/joy/Typography";

const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Required'),
    businessName: Yup.string().required('Required'),
    incorporationNumber: Yup.string().required('Required'),
    taxNumber: Yup.string().required('Required'),
    address: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
});

export default function LinkKYBPersonalInformationForm({ useLinkKYB }) {
    const { handleBusinessInformation, isLoading } = useLinkKYB();

    const formik = useFormik({
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: {
            email: '',
            businessName: '',
            incorporationNumber: '',
            taxNumber: '',
            address: '',
            country: '',
        },
        validationSchema,
        onSubmit: (values) => {
            handleBusinessInformation(values);
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Typography level="h3" mb={2}>Enter your business information</Typography>
            <Stack direction="column" spacing={2}>
                <FormControl error={!!formik.errors.email}>
                    <FormLabel>Email</FormLabel>
                    <Input
                        required
                        type="text"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                    />
                    {formik.errors.email && (
                        <FormHelperText>{formik.errors.email}</FormHelperText>
                    )}
                </FormControl>
                <FormControl error={!!formik.errors.businessName}>
                    <FormLabel>Business Name</FormLabel>
                    <Input
                        required
                        type="text"
                        name="businessName"
                        value={formik.values.businessName}
                        onChange={formik.handleChange}
                    />
                    {formik.errors.businessName && (
                        <FormHelperText>{formik.errors.businessName}</FormHelperText>
                    )}
                </FormControl>
                <FormControl error={!!formik.errors.incorporationNumber}>
                    <FormLabel>Incorporation Number</FormLabel>
                    <Input
                        required
                        type="text"
                        name="incorporationNumber"
                        value={formik.values.incorporationNumber}
                        onChange={formik.handleChange}
                    />
                    {formik.errors.incorporationNumber && (
                        <FormHelperText>{formik.errors.incorporationNumber}</FormHelperText>
                    )}
                </FormControl>
                <FormControl error={!!formik.errors.taxNumber}>
                    <FormLabel>Tax Number</FormLabel>
                    <Input
                        required
                        type="text"
                        name="taxNumber"
                        value={formik.values.taxNumber}
                        onChange={formik.handleChange}
                    />
                    {formik.errors.taxNumber && (
                        <FormHelperText>{formik.errors.taxNumber}</FormHelperText>
                    )}
                </FormControl>
                <FormControl error={!!formik.errors.country}>
                    <FormLabel>Country Of Incorporation</FormLabel>
                    <CountryInput
                        name="country"
                        value={formik.values.country}
                        setFieldValue={formik.setFieldValue}
                        formik={formik}
                    />
                    {formik.errors.country && (
                        <FormHelperText>{formik.errors.country}</FormHelperText>
                    )}
                </FormControl>
                <FormControl error={!!formik.errors.address}>
                    <FormLabel>Address</FormLabel>
                    <Input
                        required
                        type="text"
                        name="address"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                    />
                    {formik.errors.address && (
                        <FormHelperText>{formik.errors.address}</FormHelperText>
                    )}
                </FormControl>
                <Button size="sm" type="submit" disabled={isLoading} loading={isLoading}>Next</Button>
            </Stack>
        </form>
    );
}