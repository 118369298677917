import { useAuth } from '../../firebase/auth';

import { query, collection, where } from "firebase/firestore";
import { db } from '../../firebase/firebase';

import DataTable from '../../components/DataTable';

import Stack from "@mui/joy/Stack";
import Chip from "@mui/joy/Chip";
import AspectRatio from "@mui/joy/AspectRatio";
import Tooltip from "@mui/joy/Tooltip"
import MenuButton from "@mui/joy/MenuButton"
import Menu from "@mui/joy/Menu"
import MenuItem from "@mui/joy/MenuItem"
import Dropdown from "@mui/joy/Dropdown"
import IconButton from "@mui/joy/IconButton"
import Typography from "@mui/joy/Typography"

import MarkEmailRead from '@mui/icons-material/MarkEmailRead';
import MobileFriendly from '@mui/icons-material/MobileFriendly';
import MoreVert from '@mui/icons-material/MoreVert';

export default function LevelsKYC({ handleLevelEdit, setLevelToDelete }) {
    const { authUser } = useAuth();

    const tableQuery = query(collection(db, "companies", authUser.company.uid, "levels"), where("isDeleted", "==", false), where("type", "==", "kyc"));
    const tableColumns = ["Name", "Information", "Documents", "Verifications", ""];
    const rowRenderer = (level) => {
        return(
            <tr>
                <td>{level.name}</td>
                <td>
                    <Stack direction="row" spacing={0.5} flexWrap="wrap" useFlexGap>
                        <Chip variant="soft" color="success" size="sm">Default Fields</Chip>
                        { level.fields.slice(0, 3).map((field) => (
                            <Chip key={field} variant="soft" color="warning" size="sm">{field.name}</Chip>
                        ))
                        }
                        { level.fields.length > 3 &&
                            <Tooltip id={`tooltip-${level.uid}`} variant="plain" title={
                                level.fields.slice(3).map((field) => (
                                    <Typography level="body-xs">{field.name}</Typography>
                                ))
                            }>
                                <Chip variant="soft" color="warning" size="sm">+{level.fields.length - 3}</Chip>
                            </Tooltip>
                        }
                    </Stack>
                </td>
                <td>
                    <Stack direction="row" spacing={0.5} flexWrap="wrap" useFlexGap>
                        {
                            level.documents.map((document) => (
                                <Chip key={document.name} variant="soft" color="primary" size="sm">{document.name}</Chip>
                            ))
                        }
                    </Stack>
                </td>
                <td>
                    <Stack direction="row" spacing={0.5}>
                        {level.verifications && level.verifications.map((verification) => (
                            <>
                                { verification === "sms" ? 
                                <Tooltip id={`tooltip-${verification}`} variant="plain" title="SMS Verification">
                                    <AspectRatio
                                        ratio="1"
                                        variant="solid"
                                        color="primary"
                                        sx={{ minWidth: 30, borderRadius: '50%' }}
                                    >
                                        <div>
                                        <MobileFriendly fontSize="md" />
                                        </div>
                                    </AspectRatio>
                                </Tooltip>
                                : 
                                <Tooltip id={`tooltip-${verification}`} variant="plain" title="Email Verification">
                                    <AspectRatio
                                        ratio="1"
                                        variant="solid"
                                        color="primary"
                                        sx={{ minWidth: 30, borderRadius: '50%' }}
                                    >
                                        <div>
                                        <MarkEmailRead fontSize="md" />
                                        </div>
                                    </AspectRatio>
                                </Tooltip>
                                }
                            </>
                        ))}
                    </Stack>
                </td>
                <td style={{textAlign:"end"}}>
                    <Dropdown>
                        <MenuButton
                            slots={{ root: IconButton }}
                        >
                            <MoreVert />
                        </MenuButton>
                        <Menu>
                            <MenuItem onClick={()=>handleLevelEdit(level.uid)}>Edit</MenuItem>
                            <MenuItem color="danger" onClick={()=>setLevelToDelete(level.uid)}>Delete</MenuItem>
                        </Menu>
                    </Dropdown>
                </td>
            </tr>
        );
        }

    return (
        <>
            <DataTable
                tableHover= {false}
                dataQuery = {tableQuery}
                columns = {tableColumns}
                pageSize = {10}
                rowRenderer={rowRenderer}
            />
        </>
    );
}
