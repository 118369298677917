import Logo from '../../components/Logo';
import { useEffect, useState, createContext, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getDoc, doc } from "firebase/firestore";
import { db, functionsURL } from '../../firebase/firebase';
import { verifyLinkEmail } from '../../firebase/otp';
import { useSnackbar } from '../../utils/SnackbarContext';
import LinkKYBCompanyInformationForm from "../../forms/link/kyb/LinkKYBCompanyInformationForm";
import LinkKYBAdditionalInformationForm from "../../forms/link/kyb/LinkKYBAdditionalInformationForm";
import LinkKYBDocumentForm from "../../forms/link/kyb/LinkKYBDocumentForm";
import LinkKYBEmailVerificationForm from "../../forms/link/kyb/LinkKYBEmailVerificationForm";

import Stepper from '@mui/joy/Stepper';
import Step from '@mui/joy/Step';
import StepIndicator from '@mui/joy/StepIndicator';
import Stack from '@mui/joy/Stack';
import Grid from '@mui/joy/Grid';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import CircularProgress from '@mui/joy/CircularProgress';
import LinearProgress from '@mui/joy/LinearProgress';

import CheckCircle from '@mui/icons-material/CheckCircle';
import { Check, Description, Email, Person, PersonSearch } from '@mui/icons-material';

const stepInformation = {
    companyInformation: {
        title: "Company Information",
        icon: <Person fontSize="xl2" />,
    },
    additionalInformation: {
        title: "Additional Information",
        icon: <PersonSearch fontSize="xl2" />,
    },
    "email": {
        title: "Email Verification",
        icon: <Email fontSize="xl2" />,
    },
    documents: {
        title: "Upload Documents",
        icon: <Description fontSize="xl2" />,
    }
}

// Create context
const LinkKYBContext = createContext();

export const useLinkKYB = () => useContext(LinkKYBContext);

export default function LinkKYB() {
    const { linkId } = useParams();

    const [customerData, setCustomerData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [isUploaded, setIsUploaded] = useState(false);
    const [isIntroduction, setIsIntroduction] = useState(true);
    const [steps, setSteps] = useState(null);
    const [link, setLink] = useState(null);
    const [level, setLevel] = useState(null);
    const [files, setFiles] = useState({});

    const navigate = useNavigate();
    const showSnackbar = useSnackbar();

    useEffect(() => {
        const docRef = doc(db, "links", linkId);
        getDoc(docRef).then((docSnap) => {
            if (docSnap.exists()) {
                const data = docSnap.data();
                setLink(data);
                setLevel(data.level);
                setCustomerData({
                    companyInformation: {},
                    additionalInformation: {},
                    documents: {},
                    emailVerified: data.emailVerified || false,
                });
            } else {
                navigate('/');
            }
        });
    }, [linkId, navigate]);

    useEffect(() => {
        if (!link || !level) return;

        if (link.expirationDate && link.expirationDate.toDate() < new Date()) {
            navigate('/');
        }

        const stepsArray = [
            { 
                name: "companyInformation", 
                completed: link.customer ? true : false
            }
        ];

        if (link.level.fields && link.level.fields.length > 0) {
            stepsArray.push(
                { 
                    name: "additionalInformation", 
                    completed: link.customer ? true : false 
                }
            );
        }

        if (level.verifications && level.verifications.length > 0) {
            level.verifications.forEach(verification => {
                stepsArray.push({ 
                    name: verification, 
                    completed: customerData.emailVerified
                });
            });
        }

        if (level.documents && level.documents.length > 0) {
            stepsArray.push({ name: "documents", completed: false });
        }

        setSteps(stepsArray);
    }, [link, level]);

    useEffect(() => {
        if (steps && steps.every(step => step.completed)) {
            setIsUploaded(true);
        }
    }, [steps]);

    const handleBusinessInformation = async (data) => {
        const companyInformation = Object.entries(data).reduce((acc, [key, value]) => {
            if (value) {
                acc[key] = value;
            }
            return acc;
        }, {});

        const newData = {
            ...customerData,
            companyInformation
        }

        if (!steps.some(step => step.name === "additionalInformation")) {
            const result = await createBusiness(newData);
            markStepCompleted("companyInformation");
            if (result && result.success) {
                setCustomerData(newData);
            }
        } else {
            markStepCompleted("companyInformation");
            setCustomerData(newData);
        }
    }

    const handleAdditionalInformation = async (data) => {
        const additionalInformation = Object.entries(data).reduce((acc, [key, value]) => {
            if (value) {
                acc[key] = value;
            }
            return acc;
        }, {});

        const newData = {
            ...customerData,
            additionalInformation
        }

        try {
            const result = await createBusiness(newData);
            if (result && result.success) {
                setCustomerData(newData);
                markStepCompleted("additionalInformation");
            } else {
                showSnackbar(result.message, "danger");
            }
        } catch (error) {
            showSnackbar(error.message, "danger");
        }
    }

    const handleEmailVerification = async (data) => {
        setIsLoading(true);
        verifyLinkEmail(linkId, data.code)
            .then((result) => {
                if (result && result.success) {
                    setCustomerData({ ...customerData, emailVerified: true });
                    markStepCompleted("email");
                } else {
                    showSnackbar(result.message, "danger");
                }
            })
            .catch((error) => {
                showSnackbar(error.message, "danger");
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const createBusiness = async (customerData) => {
        setIsLoading(true);
        let data = {
            ...customerData,
            link: linkId,
            status: {
                status: 'pending',
                fields: 'pending',
                documents: level.documents.reduce((acc, document) => ({ ...acc, [document]: "pending" }), {}),
            }
        }

        try {
            const result = await fetch(functionsURL + '/setInformation', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            return await result.json();
        } catch (error) {
            showSnackbar(error.message, "danger");
            return null;
        } finally {
            setIsLoading(false);
        }
    }

    const handleSubmit = async () => {
        setIsUploading(true);
        let formData = new FormData();

        // Create a map to store the new filenames
        const renamedFiles = {};

        Object.entries(files).forEach(([key, value]) => {
            // Generate a unique filename using the original filename and a timestamp
            const uniqueFilename = `${key}_${Date.now()}_${value.name}`;
            renamedFiles[key] = uniqueFilename;

            // Append the original key but the value (file) will be uploaded with the new filename
            formData.append(key, new File([value], uniqueFilename, { type: value.type }));
        });

        formData.append('link', linkId);

        try {
            const result = await fetch(functionsURL + '/uploadDocuments', {
                method: 'POST',
                body: formData,
            });

            if (result.ok) {
                setIsUploading(false);
                setIsUploaded(true);
            } else {
                setIsUploading(false);
                showSnackbar("Error uploading documents", "danger");
            }
        } catch (error) {
            setIsUploading(false);
            showSnackbar(error.message, "danger");
        }
    }

    const markStepCompleted = (stepName) => {
        setSteps((prevSteps) => prevSteps.map(step => 
            step.name === stepName ? { ...step, completed: true } : step
        ));
    }

    function LinkKYBIntroduction() {
        return (
            <>
                <Typography level="h3" mb={1}>Verify Your Business</Typography>
                <Typography level="body-sm">We will get you through a simple process to verify your business.</Typography>
                <Stepper 
                orientation="vertical" 
                size="lg"
                sx={{
                    "--StepIndicator-size": "42px",
                    "--Stepper-verticalGap": "24px",
                    my:3
                }} 
                >
                    {steps.map((step, index) => {
                        return (
                            <Step key={step.name}
                            indicator={
                                <StepIndicator 
                                    variant={step.completed ? "solid" : "soft"}
                                    color="primary">
                                    {
                                        step.completed ? 
                                        <Check /> 
                                        :
                                        stepInformation[step.name].icon
                                    }
                                </StepIndicator>
                            }>
                                <Box>
                                    <Typography level="body-xs">Step {index+1}</Typography>
                                    <Typography level="title-md">{stepInformation[step.name].title}</Typography>
                                </Box>
                            </Step>
                        );
                    })}
                </Stepper>
                <Button size="sm" onClick={()=>setIsIntroduction(false)} sx={{width:1}}>Start Verification</Button>
                <Typography level="body-sm" textAlign="center" mb={1}>You are about to submit sensitive information that will only be shared with "{link.companyName}".</Typography>
            </>
        );
    }

    function LinkKYBFinal() {
        return (
            <>
                {isUploading &&
                    <Stack direction="column" spacing={2}>
                        <CircularProgress />
                        <Typography level="h3" mb={2}>Uploading</Typography>
                        <Typography level="body-md">Please wait while your information is uploading</Typography>
                    </Stack>
                }
                {isUploaded &&
                    <Stack direction="column" spacing={2}>
                        <CheckCircle color="primary" />
                        <Typography level="h3" mb={2}>Application Sent</Typography>
                        <Typography level="body-md">{link.companyName} has received your application. Verification will be completed once your information is confirmed.</Typography>
                    </Stack>
                }
            </>
        );
    }

    const renderForm = () => {
        const uncompletedStep = steps.find(step => !step.completed);

        switch (uncompletedStep?.name) {
            case "companyInformation":
                return <LinkKYBCompanyInformationForm useLinkKYB={useLinkKYB} />;
            case "additionalInformation":
                return <LinkKYBAdditionalInformationForm useLinkKYB={useLinkKYB} />;
            case "email":
                return <LinkKYBEmailVerificationForm useLinkKYB={useLinkKYB} />;
            case "documents":
                return <LinkKYBDocumentForm useLinkKYB={useLinkKYB} />;
            default:
                return null;
        }
    }

    return (
        <Grid container justifyContent="center" p={2}>
            {level && steps &&
                <Grid xs={12} md={8} lg={6}>
                    <Box p={1}>
                        <Box mb={2}>
                            <Logo />
                        </Box>
                        {
                           !isUploading && !isUploaded &&  !isIntroduction && 
                            <Box my={2}>
                                <LinearProgress size="lg" determinate value={((steps.filter(step => step.completed).length) + 1) / steps.length * 100} />
                            </Box>
                        }
                        <>
                            {isIntroduction && <LinkKYBIntroduction />}
                            {(isUploading || isUploaded) && <LinkKYBFinal />}
                                <LinkKYBContext.Provider
                                value={{
                                    linkId,
                                    customerData,
                                    handleBusinessInformation,
                                    handleAdditionalInformation,
                                    handleEmailVerification,
                                    handleSubmit,
                                    isLoading,
                                    level,
                                    files,
                                    setFiles,
                                }}
                            >
                                {!isUploading && !isUploaded && !isIntroduction && renderForm()}
                            </LinkKYBContext.Provider>
                        </>
                    </Box>
                </Grid>
            }
        </Grid>
    )
}
