import { useEffect, useState } from "react";
import { getDocs, limit, query, startAfter, where } from "firebase/firestore";
import TablePlaceholder from "./TablePlaceholder";

import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import Chip from "@mui/joy/Chip";
import Button from "@mui/joy/Button";
import Table from "@mui/joy/Table";
import Input from "@mui/joy/Input";
import Drawer from "@mui/joy/Drawer";
import DialogTitle from "@mui/joy/DialogTitle";
import ModalClose from "@mui/joy/ModalClose";
import IconButton from "@mui/joy/IconButton";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";

import FilterAlt from "@mui/icons-material/FilterAlt";
import Search from "@mui/icons-material/Search";
import QuestionMark from "@mui/icons-material/QuestionMark";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

export default function DataTable({
    dataQuery,
    columns,
    rowRenderer,
    pageSize,
    selectColumn=false,
    tableHover=true,
    searchField=null,
    filterForm=null,
    filters=null,
    emptyMessage=null
}){

    const [data, setData] = useState(null);
    const [pageData, setPageData] = useState([]);
    const [page, setPage] = useState(1);
    const [lastDoc, setLastDoc] = useState(null);
    const [lastPage, setLastPage] = useState(null);


    const [constraints, setConstraints] = useState();
    const [showFilterModal, setShowFilterModal] = useState(false);

    const [searchQuery, setSearchQuery] = useState(null);
    const [lastQuery, setLastQuery] = useState();

    if(selectColumn){
        columns = ["", ...columns];
    }
    
    const fetchData = async (afterDoc=null) => {
        let q = query(dataQuery, limit(pageSize+1));
        if(constraints && constraints.length>0){
            constraints.forEach((constraint)=>{
                q = query(q, constraint)
            })
        }
        if(filters && filters.length>0){
            filters.forEach((filter)=>{
                q = query(q, filter)
            });
        }
        if(afterDoc){
            q = query(q, startAfter(afterDoc))
        }
        const snapshot = await getDocs(q)
        if (snapshot.docs.length <= pageSize) {
            setLastPage(page);
        }
        setLastDoc(snapshot.docs[snapshot.docs.length === pageSize + 1 ? snapshot.docs.length - 2 : snapshot.docs.length - 1]);
        
        let updatedData = [];
        snapshot.forEach((doc) => {
            updatedData.push({
                uid: doc.id,
                ...doc.data(),
            });
        });
        updatedData = updatedData.slice(0, pageSize);
        return updatedData;
    }

    useEffect(() => {
        if(page > pageData.length && page){
            fetchData(lastDoc)
            .then((resultData) => {
                setPageData([...pageData, resultData]);
                setData(resultData);
            });
        }else{
            setData(pageData[page-1]);
        }
    }, [page]);

    useEffect(() => {
        setData(null);
        setPageData([]);
        setLastPage(null);
        setLastDoc(null);
        setPage(1);
        setShowFilterModal(false);
        fetchData()
        .then((resultData) => {
            setPageData([...pageData, resultData]);
            setData(resultData);
        });
    }, [constraints, filters])

    const handleSearchQuery = (e) => {
        const query = e.target.value.trim().toLowerCase();
        setSearchQuery(query);
    }

    const handleSearch = (e) => {
        e.preventDefault();
        if (searchQuery === lastQuery) {
            return;
        }
        if (searchQuery === ""){
            setConstraints();
            return;
        }
        if (searchQuery && searchQuery.trim().length > 0) {
            setConstraints([where(searchField, "array-contains-any", searchQuery.split(" "))])
        }
        setLastQuery(searchQuery);
    }

    const defaultEmptyMessage = () => {
        return (
            <tr>
                <td colSpan={columns.length}>
                    <Stack direction="column" alignItems="center" p={4}>
                        <Avatar size="sm" variant="soft" color="neutral">
                            <QuestionMark fontSize="xl"/>
                        </Avatar>
                        <Typography level="body-xs">
                            No records found
                        </Typography>
                    </Stack>
                </td>
            </tr>
        );
    }

    const handleNext = async () => {
        setPage(page+1);
    }

    const handlePrev = async () => {
        setPage(page-1);
    }

    return(
        <form onSubmit={handleSearch}>
        <Box>
            { (searchField || filters) &&
            <Stack direction="row" spacing={1} pb={2}>
                { searchField &&
                    <Input 
                        size="sm"
                        placeholder="Search"
                        onChange={handleSearchQuery}
                        sx={{flexGrow:{xs:1, sm:0}}}
                        endDecorator={
                            <IconButton type="submit">
                                <Search />
                            </IconButton>
                        }    
                    />
                }
                {
                    filterForm &&
                    <Button
                    variant="outlined"
                    color="neutral"
                    size="sm" 
                    onClick={()=>setShowFilterModal(true)}
                    startDecorator={<FilterAlt />}
                    endDecorator={
                        filters && filters.length > 0 &&
                        <Chip variant="solid" color="primary" size="sm">{filters.length}</Chip>
                    }
                    >
                        Filter
                    </Button>
                }
            </Stack>
            }
            <Card variant="outlined">
                <CardContent>
                    <Sheet sx={{background: "transparent"}}>
                        <Table
                            hoverRow={tableHover}
                            sx={{
                                '--TableCell-headBackground': 'transparent',
                                '--Table-headerUnderlineThickness': '1px',
                                '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                                '--TableCell-paddingY': '4px',
                                '--TableCell-paddingX': '8px',
                            }}
                        >
                            <thead>
                                <tr>
                                {
                                    columns.map((column, index) => {
                                        if(selectColumn && index === 0){
                                            return <th key={column} style={{ width: 50+"px" }}></th>
                                        }else{
                                            return <th key={column} style={{ padding: '12px 6px', fontSize: "0.9em"}}>{column}</th>
                                        }
                                    })
                                }
                                </tr>
                            </thead>
                            <tbody style={{fontSize: "0.95em"}}>
                                {data && data.map(rowRenderer)}
                                {data && data.length == 0 && (emptyMessage || defaultEmptyMessage())}
                                {!data && <TablePlaceholder columnCount={columns.length}/>}
                            </tbody>
                        </Table>
                    </Sheet>
                { filterForm && 
                <Drawer anchor="right" open={showFilterModal} onClose={()=>setShowFilterModal(false)}>
                    <DialogTitle>Filters</DialogTitle>
                    <ModalClose />
                    <Stack p={2} spacing={2}>
                        {filterForm}
                    </Stack>
                </Drawer>
                }
                </CardContent>
            </Card>
            { (page!==1 || page!==lastPage) &&
                <Stack 
                direction="row" 
                justifyContent="space-between" 
                mt={2}>
                    <Button
                    variant="outlined"
                    color="neutral"
                    sx={{display:page===1?"none":"inline-flex", mr:"auto"}} 
                    size="sm"
                    startDecorator={<ArrowBackIos />}
                    onClick={handlePrev}>
                        Previous
                    </Button>
                    <Button
                    variant="outlined"
                    color="neutral"
                    sx={{display:(!data || page===lastPage) ? "none" : "inline-flex", ml:"auto"}} 
                    size="sm"
                    endDecorator={<ArrowForwardIos />}
                    onClick={handleNext}>
                        Next
                    </Button>
                </Stack>
            }
        </Box>
        </form>
    );
}