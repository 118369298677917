import { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { useAuth } from "../../firebase/auth";
import { useNavigate } from "react-router-dom"; // Updated to use useNavigate

import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import FormLabel from "@mui/joy/FormLabel";
import Button from "@mui/joy/Button";
import Skeleton from "@mui/joy/Skeleton";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";

export default function LevelSelectForm({ type, handleOnChange, selectedLevel }) {
    const [levelsArray, setLevelsArray] = useState(null);
    const { authUser } = useAuth();
    const navigate = useNavigate(); // useNavigate hook to programmatically navigate

    useEffect(() => {
        const q = query(
            collection(db, "companies", authUser.company.uid, "levels"),
            where("isDeleted", "==", false),
            where("type", "==", type)
        );
        getDocs(q).then((snapshot) => {
            let levels = [];
            snapshot.docs.map((doc) => (
                levels.push({
                    id: doc.id,
                    ...doc.data()
                })
            ));
            setLevelsArray(levels);
        });
    }, [authUser, type]);

    const handleLevelSelect = (event, value) => {
        const levelId = value;
        const level = levelsArray.find(level => level.id === levelId);
        handleOnChange(level);
    };

    const handleCreateLevelClick = () => {
        // Navigate to /levels and pass state to open the create modal
        navigate('/levels', { state: { create: true, type } });
    };

    return (
        <form>
            {!levelsArray && <Skeleton variant="rectangular" height={36} />}
            {levelsArray && levelsArray.length > 0 && (
                <>
                    <FormLabel>{type.toUpperCase()} Level</FormLabel>
                    <Select
                        placeholder="Select a level"
                        onChange={handleLevelSelect}
                        value={selectedLevel ? selectedLevel.id : null} // Bind value to selectedLevel
                    >
                        {levelsArray.map((level) => (
                            <Option key={level.id} value={level.id}>{level.name}</Option>
                        ))}
                    </Select>
                </>
            )}
            {levelsArray && levelsArray.length === 0 && (
                <Stack direction="column" spacing={2}>
                    <Typography level="body-sm">You don't have any levels set.</Typography>
                    <Button onClick={handleCreateLevelClick} size="sm" sx={{ width: 1 }}>
                        Create Level
                    </Button>
                </Stack>
            )}
        </form>
    );
}
