import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth, useCompany } from '../../firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase/firebase';

import Button from '@mui/joy/Button';
import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import FormHelperText from '@mui/joy/FormHelperText';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Textarea from '@mui/joy/Textarea';
import CountryInput from '../../components/form/CountryInput';
import { useSnackbar } from '../../utils/SnackbarContext';

export default function Company() {
    const { authCompany } = useCompany();
    const { authUser } = useAuth();
    const showSnackbar = useSnackbar();

    // Define Yup validation schema for form fields
    const validationSchema = Yup.object({
        businessName: Yup.string().required('Company Name is required').min(2, 'Company Name must be at least 2 characters'),
        country: Yup.string(),
        taxNumber: Yup.string(),
        incorporationNumber: Yup.string(),
        address: Yup.string()
    });

    // Initialize Formik with initial values and validation schema
    const formik = useFormik({
        validateOnBlur: false,
        validateOnChange: false,
        initialValues: {
            businessName: authCompany?.name || '',
            country: authCompany?.country || '',
            taxNumber: authCompany?.taxNumber || '',
            incorporationNumber: authCompany?.incorporationNumber || '',
            address: authCompany?.address || '',
        },
        validationSchema: validationSchema, 
        onSubmit: async (values) => {
            formik.setSubmitting(true);
            try {
                const companyRef = doc(db, "companies", authUser.company.uid);
                await updateDoc(companyRef, {
                    name: values.businessName,
                    country: values.country,
                    taxNumber: values.taxNumber,
                    incorporationNumber: values.incorporationNumber,
                    address: values.address,
                });
                showSnackbar('Company information updated successfully.', 'success');
            } catch (error) {
                showSnackbar('Failed to update company information.', 'danger');
            }
            finally {
                formik.setSubmitting(false);
            }
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack direction="column" spacing={2}>
                <Box>
                    <Box sx={{ mb: 2 }}>
                        <Typography level="title-md">Company Information</Typography>
                    </Box>
                    <Stack direction="column" spacing={2}>

                        {/* Company Name Field */}
                        <FormControl error={!!formik.errors.businessName}>
                            <FormLabel>Company Name</FormLabel>
                            <Input
                                type="text"
                                name="businessName"
                                value={formik.values.businessName}
                                onChange={formik.handleChange}
                            />
                            {formik.errors.businessName &&
                                <FormHelperText>{formik.errors.businessName}</FormHelperText>
                            }
                        </FormControl>

                        {/* Country of Incorporation Field */}
                        <FormControl error={!!formik.errors.country}>
                            <FormLabel>Country of Incorporation</FormLabel>
                            <CountryInput
                                name="country"
                                value={formik.values.country}
                                setFieldValue={formik.setFieldValue}
                                formik={formik}
                            />
                            {formik.errors.country &&
                                <FormHelperText>{formik.errors.country}</FormHelperText>
                            }
                        </FormControl>

                        {/* Tax No Field */}
                        <FormControl error={!!formik.errors.taxNumber}>
                            <FormLabel>Tax No</FormLabel>
                            <Input
                                type="text"
                                name="taxNumber"
                                value={formik.values.taxNumber}
                                onChange={formik.handleChange}
                            />
                            {formik.errors.taxNumber &&
                                <FormHelperText>{formik.errors.taxNumber}</FormHelperText>
                            }
                        </FormControl>

                        {/* Incorporation No Field */}
                        <FormControl error={!!formik.errors.incorporationNumber}>
                            <FormLabel>Incorporation No</FormLabel>
                            <Input
                                type="text"
                                name="incorporationNumber"
                                value={formik.values.incorporationNumber}
                                onChange={formik.handleChange}
                            />
                            {formik.errors.incorporationNumber &&
                                <FormHelperText>{formik.errors.incorporationNumber}</FormHelperText>
                            }
                        </FormControl>

                        {/* Registered Address Field */}
                        <FormControl error={!!formik.errors.address}>
                            <FormLabel>Registered Address</FormLabel>
                            <Textarea
                                type="text"
                                name="address"
                                value={formik.values.address}
                                onChange={formik.handleChange}
                                minRows={2}
                            />
                            {formik.errors.address &&
                                <FormHelperText>{formik.errors.address}</FormHelperText>
                            }
                        </FormControl>
                    </Stack>
                </Box>
                <Box sx={{ mt: 2 }}>
                    <Button size="sm" type="submit" loading={formik.isSubmitting} disabled={formik.isSubmitting}>Save</Button>
                </Box>
            </Stack>
        </form>
    );
}
