import { useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from './../../firebase/firebase';

import Box from '@mui/joy/Box';
import Stack from '@mui/joy/Stack';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import Typography from '@mui/joy/Typography';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import ModalOverflow from '@mui/joy/ModalOverflow';
import DialogTitle from '@mui/joy/DialogTitle';

import { plans, benefits } from '../../utils/constants';
import { Check } from '@mui/icons-material';
import { KeyboardArrowRight } from '@mui/icons-material';
import { useSnackbar } from '../../utils/SnackbarContext';
import AuthControl from '../../components/AuthControl';


export default function Resubscribe({type}) {

    const [page, setPage] = useState(0);
    const showSnackbar = useSnackbar();

    const navigateToPortal = () => {
        setLinkLoading(true);
        httpsCallable(functions, 'createStripePortal')()
        .then((result) => {
            window.location.href = result.data.url;
        })
        .catch((error) => {
            showSnackbar("An error occurred: " + error, "danger");
            setLinkLoading(false);
        });
    }

    const createCheckoutLink = (priceId) => {
        setLinkLoading(true);
        httpsCallable(functions, 'createStripeCheckout')({priceId})
        .then((result) => {
            window.location.href = result.data.url;
        })
        .catch((error) => {
            showSnackbar("An error occurred: " + error, "danger");
            setLinkLoading(false);
        });
    }

    const [linkLoading, setLinkLoading] = useState(false);

    const PlanCard = ({ title, description, price, priceId, features, highlight=false }) => {
        return (
        <Card 
        size="lg"
        variant={highlight ? "solid" : "outlined"}
        color={highlight ? "primary" : "neutral"}
        invertedColors={highlight}
        >
            <Stack direction="row" justifyContent="space-between">
                <Typography level="h2">{title}</Typography>
                { highlight &&
                <Typography level="body-md">
                <Chip>
                    Best Deal
                </Chip>
                </Typography>
                }
            </Stack>
            <Typography level="body-md">{description}</Typography>
            <Typography level="h1" sx={{ mr: 'auto' }}>
                {price}{' '}
                <Typography level="body-md" fontWeight={400}>
                / month
                </Typography>
            </Typography>
            <Divider/>
            <List size="sm" sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))' }}>
            {
                features.map(feature => 
                    <ListItem>
                        <ListItemDecorator>
                        <Check />
                        </ListItemDecorator>
                        {feature}
                    </ListItem>
                )
            }
            </List>
            <CardActions>
            <Button
                size="sm"
                endDecorator={<KeyboardArrowRight />}
                onClick={()=>createCheckoutLink(priceId)}
                loading={linkLoading}
                disabled={linkLoading}
            >
                Select Plan
            </Button>
            </CardActions>
        </Card>
        );
    }

    return (
        <Modal open={true}>
            <ModalOverflow>
                
            <ModalDialog>
                { page === 0 &&
                <>
                    { type === "trialing" ?
                    <>
                        <Typography level="title-lg">Your free trial has expired</Typography>
                        <Typography level="body-sm" mb={2}>If you want to continue to benefit from TrueKYC, it's time to upgrade your plan.</Typography>
                    </>
                    :
                    <>
                        <Typography level="title-lg">Your subscription is {type === "canceled" ? "canceled" : "inactive"}</Typography>
                        <Typography level="body-sm" mb={2}>If you want to continue to benefit from TrueKYC, it's time to reactivate your subscription.</Typography>
                    </>
                    }
                    <Stack direction="column" mb={2} spacing={1}>
                        { benefits.map(benefit => 
                            <Stack direction="row" spacing={1}>
                                <Check color="success" fontSize="12"/>
                                <Box>
                                    <Typography level="title-sm">{benefit.title}</Typography>
                                    <Typography level="body-sm">{benefit.description}</Typography>
                                </Box>
                            </Stack>
                        )}
                    </Stack>
                    <AuthControl.Admin>
                        <Stack direction="column" spacing={1}>
                            { (type === "trialing" || type === "canceled") ?
                                <Button size="sm" variant="solid" color="primary" onClick={()=>setPage(1)}>
                                    {
                                        type === "trialing" ?
                                        "Upgrade"
                                        :
                                        "Subscribe"
                                    }
                                </Button>
                            :
                                <Button size="sm" variant="solid" color="primary" onClick={navigateToPortal} loading={linkLoading} disabled={linkLoading}>Manage Subscription</Button>
                            }
                            <Button size="sm" variant="soft" color="neutral" onClick={()=>{window.location.href = '/logout'}}>Logout</Button>
                        </Stack>
                    </AuthControl.Admin>
                    <AuthControl.Officer>
                        <Typography level="body-sm" mb={2}>
                            Contact your system admin to resolve this issue.
                        </Typography>
                    </AuthControl.Officer>
                </>
                }
                { page === 1 &&
                <>
                    <DialogTitle>Choose a plan</DialogTitle>
                    <Stack direction={{xs:"column", md:"row"}} spacing={2}>
                        { plans.map(plan => 
                            <PlanCard {...plan}/>
                        )}
                    </Stack>
                    <Button size="sm" variant="soft" color="neutral"
                    onClick={()=>{window.location.href = '/logout'}}>Logout</Button>
                </>
                }
            </ModalDialog>
            </ModalOverflow>
        </Modal>
  );
}