import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import Stack from "@mui/joy/Stack";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import LinearProgress from "@mui/joy/LinearProgress";
import { useNavigate } from "react-router-dom";
import AuthControl from "./AuthControl";

export default function TrialCard({name, limit}){

    const navigate = useNavigate();

    return(
        <Card variant="soft">
            <CardContent>
                <Typography level="h3">Welcome to your free trial, {name}!</Typography>
                <Typography level="body-sm">Try TrueKYC free for 7 days. After the trial period, please upgrade to a paid plan to continue using the service</Typography>
                <Stack direction={{xs:"column", lg: "row"}} mt={2} spacing={2} gap={2} alignItems={{xs:"stretch", lg:"end"}}>
                    <Box flexGrow={1}>
                        <Typography level="title-md">Actions remaining</Typography>
                        <Typography level="title-lg" mb={2}>{limit}</Typography>
                        <LinearProgress variant="solid" determinate value={(limit)/20*100} />
                    </Box>
                    <AuthControl.Admin>
                        <Box>
                            <Button size="sm" variant="solid" color="primary" onClick={()=>navigate("/settings/billing")}>Upgrade your plan</Button>
                        </Box>
                    </AuthControl.Admin>
                </Stack>
            </CardContent>
        </Card>
    );
}
