import Page from '../../layout/Page';

import { db } from '../../firebase/firebase';
import { useAuth } from '../../firebase/auth';
import { collection, query, where, doc, deleteDoc } from 'firebase/firestore';
import { useState } from 'react';
import { removeMember } from '../../firebase/members';
import { format } from 'date-fns';
import { useSnackbar } from "../../utils/SnackbarContext";

import CustomModal from '../../components/CustomModal';
import TabListStyled from '../../components/TabListStyled';
import Header from '../../layout/Header';
import DataTable from '../../components/DataTable';
import MemberCreateForm from '../../forms/members/MemberCreateForm';

import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Drawer from "@mui/joy/Drawer";
import ModalClose from "@mui/joy/ModalClose";
import DialogTitle from "@mui/joy/DialogTitle";
import Tabs from "@mui/joy/Tabs";
import Tab from "@mui/joy/Tab";
import TabPanel from "@mui/joy/TabPanel";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import MenuButton from "@mui/joy/MenuButton";
import Dropdown from "@mui/joy/Dropdown";
import IconButton from "@mui/joy/IconButton";
import Chip from "@mui/joy/Chip";
import CopyAllIcon from '@mui/icons-material/CopyAll';

import Add from "@mui/icons-material/Add";
import MoreVert from "@mui/icons-material/MoreVert";

export default function Members() {
    const { authUser } = useAuth();
    const [memberToDelete, setMemberToDelete] = useState(null);
    const [invitationToDelete, setInvitationToDelete] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [inviteModal, setInviteModal] = useState(false);
    const [showDeleteInvitationConfirmation, setShowDeleteInvitationConfirmation] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [refresh, setRefresh] = useState(0);

    const showSnackbar = useSnackbar();

    const handleDeleteClick = (member) => {
        setMemberToDelete(member);
        setShowConfirmation(true);
    };

    const handleRemoveMember = async () => {
        const email = memberToDelete.email;
        setShowConfirmation(false);
        setDeleteLoading(true);
        try {
            const result = await removeMember(email);
            if (result.success) {
                showSnackbar(result.message, "success");
                setRefresh(refresh + 1);
            } else {
                showSnackbar(result.message, "danger");
            }
        } catch (error) {
            showSnackbar(error.message, "danger");
        } finally {
            setDeleteLoading(false);
        }
    };

    const handleDeleteInvitationClick = (invitation) => {
        setInvitationToDelete(invitation);
        setShowDeleteInvitationConfirmation(true);
    };

    const handleDeleteInvitation = async () => {
        setShowDeleteInvitationConfirmation(false);
        setDeleteLoading(true);
        try {
            await deleteDoc(doc(db, "invitations", invitationToDelete.uid));
            showSnackbar("Invitation deleted successfully.", "success");
            setRefresh(refresh + 1);
        } catch (error) {
            showSnackbar(error.message, "danger");
        } finally {
            setDeleteLoading(false);
        }
    };

    const handleCopyLink = (link) => {
        navigator.clipboard.writeText(link).then(() => {
            showSnackbar('Link copied to clipboard');
        }).catch((err) => {
            showSnackbar("Failed to copy link", "danger");
        });
    };

    const headerButtons = () => (
        <Button
            size="sm"
            variant="solid"
            color="primary"
            startDecorator={<Add />}
            onClick={() => setInviteModal(true)}
        >
            Create New
        </Button>
    );

    const membersQuery = query(
        collection(db, "companies", authUser.company.uid, "members"),
        where("isDeleted", "!=", true)
    );
    const invitationsQuery = query(
        collection(db, "invitations"),
        where("company", "==", authUser.company.uid)
    );

    return (
        <Page>
            <Tabs aria-label="Member Tabs" defaultValue="members" sx={{ bgcolor: "transparent" }}>
                <Header
                    title="Team"
                    tabs={
                        <TabListStyled>
                            <Tab value="members">Members</Tab>
                            <Tab value="invitations">Invitations</Tab>
                        </TabListStyled>
                    }
                    controls={headerButtons()}
                />
                <TabPanel keepMounted sx={{ px: 0 }} value="members">
                    <Box py={2}>
                        <DataTable
                            key={refresh}
                            dataQuery={membersQuery}
                            columns={["Name", "Email", "Role", ""]}
                            pageSize={10}
                            rowRenderer={(member) => (
                                <tr key={member.uid}>
                                    <td>{member.firstName} {member.lastName}</td>
                                    <td>{member.email}</td>
                                    <td>{member.role.charAt(0).toUpperCase() + member.role.slice(1)}</td>
                                    <td style={{ textAlign: "end" }}>
                                        {member.uid !== authUser.uid && (
                                            <Dropdown>
                                                <MenuButton
                                                    disabled={member.role === "admin"}
                                                    slots={{ root: IconButton }}
                                                >
                                                    <MoreVert />
                                                </MenuButton>
                                                <Menu>
                                                    <MenuItem color="danger" onClick={() => handleDeleteClick(member)}>Remove</MenuItem>
                                                </Menu>
                                            </Dropdown>
                                        )}
                                    </td>
                                </tr>
                            )}
                        />
                    </Box>
                </TabPanel>
                <TabPanel keepMounted sx={{ px: 0 }} value="invitations">
                    <Box py={2}>
                        <DataTable
                            key={refresh}
                            dataQuery={invitationsQuery}
                            columns={["Date", "Email", "Role", "Link", ""]}
                            pageSize={10}
                            rowRenderer={(invitation) => (
                                <tr key={invitation.id}>
                                    <td>{format(invitation.createdAt.toDate(), "dd/MM/yyyy' 'HH:mm")}</td>
                                    <td>{invitation.email}</td>
                                    <td>{invitation.role.charAt(0).toUpperCase() + invitation.role.slice(1)}</td>
                                    <td>
                                        <Chip 
                                            size="sm"
                                            color="primary"
                                            onClick={() => handleCopyLink(`${window.location.origin}/invitations/${invitation.uid}`)}
                                            startDecorator={<CopyAllIcon />}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            {window.location.origin}/invitations/{invitation.uid}
                                        </Chip>
                                    </td>
                                    <td style={{ textAlign: "end" }}>
                                        <Dropdown>
                                            <MenuButton slots={{ root: IconButton }}>
                                                <MoreVert />
                                            </MenuButton>
                                            <Menu>
                                                <MenuItem color="danger" onClick={() => handleDeleteInvitationClick(invitation)}>Delete</MenuItem>
                                            </Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            )}
                        />
                    </Box>
                </TabPanel>
            </Tabs>
            <Drawer anchor="right" open={inviteModal} onClose={() => setInviteModal(false)}>
                <DialogTitle>Invite A Member</DialogTitle>
                <ModalClose />
                <Box p={1.5}>
                    <MemberCreateForm closeDrawer={
                        () => {
                            setInviteModal(false);
                            setRefresh(refresh + 1);
                        }} />
                </Box>
            </Drawer>
            <CustomModal.Delete open={showConfirmation} onClose={() => setShowConfirmation(false)} loading={deleteLoading} message="Are you sure you want to remove this member?" handleDelete={handleRemoveMember} />
            <CustomModal.Delete open={showDeleteInvitationConfirmation} onClose={() => setShowDeleteInvitationConfirmation(false)} loading={deleteLoading} message="Are you sure you want to delete this invitation?" handleDelete={handleDeleteInvitation} />
        </Page>
    );
}
