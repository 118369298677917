import { useState } from "react";
import { useCompany } from "../../firebase/auth";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase/firebase";

import Stack from '@mui/joy/Stack';
import Button from '@mui/joy/Button';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import Chip from '@mui/joy/Chip';
import Divider from '@mui/joy/Divider';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemDecorator from '@mui/joy/ListItemDecorator';
import CardContent from '@mui/joy/CardContent';
import CardActions from '@mui/joy/CardActions';
import Typography from '@mui/joy/Typography';

import { plans } from '../../utils/constants';

import { Check, KeyboardArrowRight } from '@mui/icons-material';

import { useSnackbar } from "../../utils/SnackbarContext";

export default function Billing() {
    const { authCompany } = useCompany();
    const [linkLoading, setLinkLoading] = useState(false);
    const showSnackbar = useSnackbar();

    // Create default portal link
    const createPortalLink = () => {
        setLinkLoading(true);
        httpsCallable(functions, 'createStripePortal')()
        .then((result) => {
            window.location.href = result.data.url;
        })
        .catch((error) => {
            showSnackbar("An error occurred: " + error, "danger");
        })
        .finally(() => {
            setLinkLoading(false);
        });
    }

    // Create checkout session link for selected plan
    const createCheckoutLink = (priceId) => {
        setLinkLoading(true);
        httpsCallable(functions, 'createStripeCheckout')({ priceId })
        .then((result) => {
            window.location.href = result.data.url;
        })
        .catch((error) => {
            showSnackbar("An error occurred: " + error, "danger");
            setLinkLoading(false);
        });
    }

    // Navigate to default portal for subscription management
    const navigateToPortal = () => {
        createPortalLink();
    }

    // Navigate to portal for upgrading the subscription
    const navigateToUpgrade = (priceId) => {
        setLinkLoading(true);
        httpsCallable(functions, 'createStripePortal')({ type: 'upgrade', priceId })
        .then((result) => {
            window.location.href = result.data.url;
        })
        .catch((error) => {
            showSnackbar("An error occurred: " + error, "danger");
            setLinkLoading(false);
        });
    }

    // Navigate to portal for updating payment method
    const navigateToPaymentMethodUpdate = () => {
        setLinkLoading(true);
        httpsCallable(functions, 'createStripePortal')({ type: 'payment' })
        .then((result) => {
            window.location.href = result.data.url;
        })
        .catch((error) => {
            showSnackbar("An error occurred: " + error, "danger");
            setLinkLoading(false);
        });
    }

    const PlanCard = ({ title, description, price, priceId, features, highlight = false, company }) => {
        return (
            <Card
                size="lg"
                variant={highlight ? "solid" : "outlined"}
                color={highlight ? "primary" : "neutral"}
                invertedColors={highlight}
            >
                <Stack direction="row" justifyContent="space-between">
                    <Typography level="h2">{title}</Typography>
                    {highlight && (
                        <Typography level="body-md">
                            <Chip>
                                Best Deal
                            </Chip>
                        </Typography>
                    )}
                </Stack>
                <Typography level="body-md">{description}</Typography>
                <Typography level="h1" sx={{ mr: 'auto' }}>
                    {price}{' '}
                    <Typography level="body-md" fontWeight={400}>
                        / month
                    </Typography>
                </Typography>
                <Divider />
                <List size="sm" sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))' }}>
                    {features.map((feature, index) => (
                        <ListItem key={index}>
                            <ListItemDecorator>
                                <Check />
                            </ListItemDecorator>
                            {feature}
                        </ListItem>
                    ))}
                </List>
                <CardActions>
                    {company.subscription.product.priceId !== priceId ? (
                        <Button
                            size="sm"
                            endDecorator={<KeyboardArrowRight />}
                            onClick={
                                company.subscription.status === "trialing" ?
                                    () => createCheckoutLink(priceId)
                                    :
                                    () => navigateToUpgrade(priceId)
                            }
                            loading={linkLoading}
                            disabled={linkLoading}
                        >
                            {company.subscription.status === "trialing" ? "Select Plan" : "Change Plan"}
                        </Button>
                    ) : (
                        <Button
                            size="sm"
                            endDecorator={<KeyboardArrowRight />}
                            disabled
                        >
                            Current Plan
                        </Button>
                    )}
                </CardActions>
            </Card>
        );
    }

    return (
        <Stack direction="column" spacing={2}>
            <Box>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography level="title-md" mb={0}>Your Plan</Typography>
                    {authCompany.subscription.status !== "trialing" &&
                        <Button size="sm" onClick={navigateToPortal} loading={linkLoading} disabled={linkLoading}>
                            Manage Subscription
                        </Button>
                    }
                </Stack>
            </Box>
            <Card>
                <CardContent>
                    <Stack direction="column" spacing={1}>
                        <Box>
                            <Typography level="body-xs">Current plan</Typography>
                            <Typography level="h2">{authCompany.subscription.status === "trialing" ? "Free Trial" : authCompany.subscription.product.name}</Typography>
                        </Box>
                        <Box>
                            <Typography level="body-xs">{"Current bill /" + authCompany.subscription.product.interval}</Typography>
                            <Typography level="body-md">
                                {"$" +
                                    authCompany.subscription.product.price
                                        .toString()
                                        .replace(/(\d+)(\d{2})$/, '$1,$2')}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography level="body-xs">Next payment due</Typography>
                            <Typography level="body-md">
                                {new Date(authCompany.subscription.current_period_end * 1000).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
                            </Typography>
                        </Box>
                    </Stack>
                </CardContent>
            </Card>
            {authCompany.subscription.status !== "trialing" &&
                <>
                <Box>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography level="title-md" mb={0}>Default Payment Method</Typography>
                            <Stack direction="row" spacing={1}>
                                <Button size="sm" onClick={navigateToPaymentMethodUpdate} loading={linkLoading} disabled={linkLoading}>
                                    Add New
                                </Button>
                                <Button size="sm" variant="soft" onClick={navigateToPortal} loading={linkLoading} disabled={linkLoading}>
                                    View All
                                </Button>
                            </Stack>
                    </Stack>
                </Box>
                <Card>
                    <CardContent>
                        <Stack direction="row" spacing={2}>
                        { authCompany.subscription.payment_method ?
                        <>
                            <Box>
                                <Typography level="body-xs">Brand</Typography>
                                <Typography level="body-md">{authCompany.subscription.payment_method?.brand.toUpperCase()}</Typography>
                            </Box>
                            <Box>
                                <Typography level="body-xs">Card Number</Typography>
                                <Typography level="h4">**** **** **** {authCompany.subscription.payment_method?.last4}</Typography>
                            </Box>
                            <Box>
                                <Typography level="body-xs">Expiration</Typography>
                                <Typography level="body-md">
                                {authCompany.subscription.payment_method?.exp_month}/{authCompany.subscription.payment_method?.exp_year}
                                </Typography>
                            </Box>
                        </>
                        :
                        <Typography level="body-md">No default payment method</Typography>
                        }
                        </Stack>
                    </CardContent>
                </Card>
                </>
            }
            <Box>
                <Typography level="title-md" mb={0}>All Plans</Typography>
            </Box>
            <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                {plans.map(plan => (
                    <PlanCard key={plan.priceId} {...plan} company={authCompany} />
                ))}
            </Stack>
        </Stack>
    );
}
