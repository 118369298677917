import { useEffect } from "react";
import { sendVerificationSMS } from "../../../firebase/otp";
import { useFormik } from "formik";
import * as Yup from "yup";

import Input from "@mui/joy/Input";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import Stack from "@mui/joy/Stack";
import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";

import CheckCircle from "@mui/icons-material/CheckCircle";
import { useSnackbar } from "../../../utils/SnackbarContext";

export default function LinkKYCVerificationForm({ useLinkKYC }) {
    const { linkId, customerData, nextStep, handleSMSVerification, isLoading } = useLinkKYC();
    const { showSnackbar } = useSnackbar();

    useEffect(() => {
        sendSMS();
    }, []);

    const sendSMS = async () => {
        const result = await sendVerificationSMS(customerData?.personalInformation?.phone, linkId);
        if (!result) {
            showSnackbar("error", "Failed to send verification SMS.");
        }
    }

    const formik = useFormik({
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: {
            code: '',
        },
        validationSchema: Yup.object({
            code: Yup.string().required('Required'),
        }),
        onSubmit: handleSMSVerification,
    });

    return (
        <>
            {customerData.smsVerified ? (
                <Stack direction="column" spacing={2}>
                    <CheckCircle color="primary" />
                    <Typography level="h3" mb={2}>SMS Verification Completed</Typography>
                    <Typography level="body-md">Your phone number is verified successfully.</Typography>
                    <Button size="sm" type="submit" onClick={nextStep}>Next</Button>
                </Stack>
            ) : (
                <form onSubmit={formik.handleSubmit}>
                    <Stack direction="column" spacing={2}>
                        <Typography level="h3" mb={2}>SMS Verification</Typography>
                        <Typography level="body-md">We sent an OTP to your phone number</Typography>
                        <FormControl error={formik.touched.code && !!formik.errors.code}>
                            <FormLabel>Code</FormLabel>
                            <Input
                                type="text"
                                name="code"
                                value={formik.values.code}
                                onChange={formik.handleChange}
                            />
                            {formik.touched.code && formik.errors.code && (
                                <FormHelperText>{formik.errors.code}</FormHelperText>
                            )}
                        </FormControl>
                        <Button size="sm" type="submit" disabled={isLoading} loading={isLoading}>Verify</Button>
                    </Stack>
                </form>
            )}
        </>
    );
}
