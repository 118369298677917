import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { sendVerificationEmail, verifyEmailCode } from "../../firebase/otp";

import Input from "@mui/joy/Input";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import FormHelperText from "@mui/joy/FormHelperText";
import Stack from "@mui/joy/Stack";

import CheckCircle from "@mui/icons-material/CheckCircle";
import { useSnackbar } from "../../utils/SnackbarContext";

export default function EmailVerificationForm({ email, onComplete }) {
    const [emailVerified, setEmailVerified] = useState(false);
    const showSnackbar = useSnackbar();

    useEffect(() => {
        sendEmail();
    }, []);

    const sendEmail = async () => {
        const result = await sendVerificationEmail(email);
        if (!result) {
            showSnackbar("Failed to send verification email.", "danger");
        }
    };

    const formik = useFormik({
        initialValues: {
            code: "",
        },
        validationSchema: Yup.object({
            code: Yup.string().required("OTP is required"),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            setSubmitting(true);
            const result = await verifyEmailCode(email, values.code);
            if (!result.success) {
                formik.setErrors({ code: result.message || "Verification failed." });
            } else {
                setEmailVerified(true);
            }
            setSubmitting(false);
        },
    });

    return (
        <>
            {emailVerified ? (
                <Stack direction="column" spacing={2}>
                    <CheckCircle color="primary" />
                    <Typography level="h3" mb={2}>E-mail Verification Completed</Typography>
                    <Typography level="body-md">The customer's email address has been successfully verified.</Typography>
                    <Button size="sm" type="submit" onClick={onComplete}>Next</Button>
                </Stack>
            ) : (
                <form onSubmit={formik.handleSubmit}>
                    <Stack direction="column" spacing={2}>
                        <Typography level="h3" mb={2}>Email Verification</Typography>
                        <Typography level="body-md">We sent an OTP to email address: {email}</Typography>
                        <FormControl error={!!formik.errors.code}>
                            <FormLabel>Code</FormLabel>
                            <Input
                                type="text"
                                name="code"
                                value={formik.values.code}
                                onChange={formik.handleChange}
                            />
                            {formik.errors.code && (
                                <FormHelperText>{formik.errors.code}</FormHelperText>
                            )}
                        </FormControl>
                        <Button size="sm" type="submit" disabled={formik.isSubmitting} loading={formik.isSubmitting}>Verify</Button>
                    </Stack>
                </form>
            )}
        </>
    );
}
