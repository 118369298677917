import { Gallery, Item } from "react-photoswipe-gallery";
import Box from "@mui/joy/Box";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import CircularProgress from "@mui/joy/CircularProgress";

import FileOpen from "@mui/icons-material/FileOpen";
import HelpOutline from "@mui/icons-material/HelpOutline";

const DocumentCard = ({ document, file, documentBlobs, handleOpenPdfModal }) => {
    const docBlob = documentBlobs[document.name]?.[file.name];
    const title = file.name === document.name ? null : file.name;

    return (
        <>
            {
                file.url ?
                    docBlob ?
                        file.url.endsWith(".pdf") ?
                            <Box>
                                <Typography level="body-xs">{title}</Typography>
                                <Button
                                    size="sm"
                                    variant="soft"
                                    color="neutral"
                                    startDecorator={<FileOpen />}
                                    onClick={() => handleOpenPdfModal(document.name, file.name)}>
                                    View Document
                                </Button>
                            </Box>
                            :
                            <Box>
                                <Gallery>
                                    <Typography level="body-xs">{title}</Typography>
                                    <Item
                                        original={docBlob.url}
                                        thumbnail={docBlob.url}
                                        width={docBlob.width}
                                        height={docBlob.height}
                                    >
                                        {({ ref, open }) => (
                                            <img ref={ref}
                                                onClick={open}
                                                src={docBlob.url}
                                                alt={file.name}
                                                style={{
                                                    width: "100px",
                                                    height: "100px",
                                                    objectFit: "cover",
                                                    cursor: "pointer",
                                                    borderRadius: "5px"
                                                }}
                                            />
                                        )}
                                    </Item>
                                </Gallery>
                            </Box>
                        :
                        <CircularProgress />
                    :
                    <Sheet color="neutral" variant="soft" p={3}>
                        <HelpOutline />
                        <Typography level="body-xs">
                            File not uploaded
                        </Typography>
                    </Sheet>
            }
        </>
    );
}

export default DocumentCard;